import React, { useState, useEffect, useRef } from 'react';
import globalAxios from '../../../globalAxios.js';

import { useAppSelector, useAppDispatch } from '../../../store/hooks.ts';
import {
	setAllFields,
	setSelectedPreset,
	setCustomPresetName,
	setIsDirty,
	setShowOverwriteCheckBox,
	setOverwritePreset,
	addSelection,
	removeSelection,
	changeItemNumber,
	reorderSelection,
	toggleGroupSelection,
	toggleItemNumbersVisibility,
} from '../../../store/slices/fieldsSlice.ts';

import { updateCustomPresets, deleteCustomPreset, updateDefaultPreset } from '../../../store/slices/userSlice.ts';

import { setSnackbarOpen, setSnackbarSeverity, setSnackbarText } from '../../../store/slices/snackbarSlice.ts';

import {
	Box,
	Button,
	Typography,
	Paper,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
	Chip,
	MenuItem,
	TextField
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import ResetIcon from '@mui/icons-material/ReplayCircleFilled';
import DeleteIcon from '@mui/icons-material/Delete';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

const fieldGroups = {
	patientDetails: ['First Name', 'Last Name', 'Title', 'Gender', 'DOB', 'Address'],
	patientContacts: ['Home', 'Work', 'Mobile', 'Email', 'Next of Kin'],
	billingDetails: ['Medicare', 'Health Fund', 'Health Fund Number', 'Account Type'],
	sessionDetails: ['Date of Service', 'Hospital', 'Surgeon', 'Assistant', 'Anaesthetist', 'Procedure'],
};

const allFields = [
	'First Name', 'Last Name', 'Title', 'Gender', 'DOB', 'Address',
	'Home', 'Work', 'Mobile', 'Email', 'Next of Kin',
	'Medicare', 'Health Fund', 'Health Fund Number', 'Account Type',
	'Date of Service', 'Hospital', 'Surgeon', 'Assistant', 'Anaesthetist', 'Procedure',
	"Item Numbers: 10",
];

const reservedPresetNames = new Set(["none", "all fields", "all-fields"]);

const Fields = () => {

	const headingRef = useRef(null);
	const [selectionsHeight, setSelectionsHeight] = useState('auto');

	// Dynamically set renderSelection height to prevent main page scroll -> instead, scroll the renderSelection container.
	useEffect(() => {
		const updateSelectionsHeight = () => {
			let bottomRef = 0
			// Get the top position of the container relative to the viewport
			if (headingRef.current) {
				const { bottom } = headingRef.current.getBoundingClientRect();
				bottomRef = bottom;
			};
			// Adjust the fixed height based on your layout, headers, footers, etc.
			const newHeight = `calc(${window.innerHeight}px - (${bottomRef}px + 52px))`;
			setSelectionsHeight(newHeight);
		};
		updateSelectionsHeight();
		window.addEventListener('resize', updateSelectionsHeight);
		return () => window.removeEventListener('resize', updateSelectionsHeight);
	}, [selectionsHeight]);


	const showItemNumbers = useAppSelector(state => state.fields.showItemNumbers);
	const selectedItemNumber = useAppSelector(state => {
		// Assuming your state structure and logic for extracting the selected item number
		const match = state.fields.fields.find(field => field.startsWith('Item Numbers:'));
		return match ? match.split(': ')[1] : '1'; // Default to '1' if not found
	});

	const [exportFileType, setExportFileType] = useState('.xlsx');

	const customPresetName = useAppSelector(state => state.fields.customPresetName);

	const handleCustomPresetNameChange = (event) => {
		dispatch(setCustomPresetName(event.target.value));
	};

	const fields = useAppSelector(state => state.fields.fields);
	const selectedPreset = useAppSelector(state => state.fields.selectedPreset);
	const dirty = useAppSelector(state => state.fields.isDirty);
	const showOverwriteCheckBox = useAppSelector(state => state.fields.showOverwriteCheckBox);
	const overwritePreset = useAppSelector(state => state.fields.overwritePreset);

	const user = useAppSelector(state => state.user);
	const dispatch = useAppDispatch();

	const [isSavingPreset, setIsSavingPreset] = useState(false);

	// Handle if preset with matching name exists.
	useEffect(() => {
		const nameExists = user.settings?.customPresets && user.settings.customPresets.hasOwnProperty(customPresetName);
		dispatch(setShowOverwriteCheckBox(nameExists));
	}, [dispatch, customPresetName, user.settings?.customPresets]);

	const handleSavePreset = async () => {
		if (customPresetName.trim() === "") {
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText("Please enter a name."));
			return;
		}
		const lowercasePresetName = customPresetName.trim().toLowerCase();

		if (reservedPresetNames.has(lowercasePresetName)) {
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText("Reserved name. Please choose a different name."));
			return;
		}

		if (user.settings?.customPresets.hasOwnProperty(customPresetName) && !overwritePreset) {
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText("Same name. Select overwrite or rename."));
			return;
		};

		setIsSavingPreset(true);

		try {
			const newPreset = {
				name: customPresetName,
				fields: fields
			};

			await globalAxios.post('/user/settings/savePreset', {
				user,
				newPreset,
			});

			// Update the Redux state with the new preset
			dispatch(updateCustomPresets(newPreset));

			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("success"));
			dispatch(setSnackbarText("Saved default settings."));
		} catch (error) {
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText(`Failed to save default settings: ${error}`));
		} finally {
			// Set new custom preset as selected.
			dispatch(setSelectedPreset(customPresetName));
			dispatch(setShowOverwriteCheckBox(false));
			dispatch(setOverwritePreset(false));
			dispatch(setIsDirty(false));
			setIsSavingPreset(false); // Reset saving state after saving process completes
		};
	};

	const handleSaveDefaultPreset = async (presetName, presetFields) => {

		const defaultPreset = {
			name: presetName,
			fields: presetFields,
		}

		console.log(defaultPreset)

		try {
			await globalAxios.post('/user/settings/defaultPreset', {
				user,
				defaultPreset,
			});

			dispatch(updateDefaultPreset(defaultPreset))

			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("success"));
			dispatch(setSnackbarText("Saved default preset."));
		} catch (error) {
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText(`Failed to save default settings: ${error}`));
		}
	};

	const handleDeletePreset = async (presetName) => {
		try {
			await globalAxios.delete('/user/settings/deletePreset', {
				data: { user, presetName }
			});

			// Update the Redux state to remove the preset
			dispatch(deleteCustomPreset(presetName));
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("success"));
			dispatch(setSnackbarText("Deleted preset successfully."));
		} catch (error) {
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText(`Failed to delete preset: ${error}`));
		}
	};



	const generateAlphabet = (length) => {
		return Array.from({ length }, (_, i) => String.fromCharCode(65 + i)); // 65 is the ASCII code for 'A'
	};
	const alphabet = generateAlphabet(fields.length);


	const getNextPresetNumber = () => {
		const prefix = `${user.firstName} preset `;
		let maxNum = 0;
		Object.keys(user.settings?.customPresets || {}).forEach(presetName => {
			if (presetName.startsWith(prefix)) {
				const suffixNum = parseInt(presetName.substring(prefix.length), 10);
				if (suffixNum >= maxNum) {
					maxNum = suffixNum + 1; // Find the highest number and increment it
				}
			}
		});
		return maxNum.toString().padStart(2, '0'); // Ensure it's at least two digits
	};

	const determinePresetName = () => {
		// You can include any logic here to generate or select the appropriate preset name
		const nextPresetNumber = getNextPresetNumber();
		return `${user.firstName} preset ${nextPresetNumber}`;
	};


	const handleCheckboxChange = (event) => {
		if (event.target.checked) {
			dispatch(addSelection(event.target.name));
		} else {
			dispatch(removeSelection(event.target.name));
		};
		if (!dirty) {
			dispatch(setCustomPresetName(determinePresetName()));
			dispatch(setSelectedPreset("none"));
			dispatch(setIsDirty(true));
		};
	};

	const handleItemNumberChange = (event) => {
		dispatch(changeItemNumber(event.target.value));
		if (!dirty) {
			dispatch(setCustomPresetName(determinePresetName()));
			dispatch(setSelectedPreset("none"));
			dispatch(setIsDirty(true));
		};
	};

	const handleExportFileTypeChange = (event) => {
		setExportFileType(event.target.value);
	};

	const handleResetFields = () => {
		dispatch(setAllFields(allFields));
	};

	const handleResetToDefault = () => {
		dispatch(setAllFields(user.settings?.defaultPreset.fields || {}));
		dispatch(setSelectedPreset(user.settings?.defaultPreset.name || "none"));
		dispatch(setIsDirty(false));
	};


	const handleGetSelectedPreset = (event) => {
		const presetName = event.target.value;
		const customPresets = user.settings?.customPresets;
		dispatch(setSelectedPreset(event.target.value));

		// Find the preset fields based on the selected preset name
		const presetFields = customPresets[presetName];

		if (presetName === "all-fields" || presetName === "none") {
			handleResetFields(); // Assuming resetFields is a function that dispatches an action to set all fields
			handleSaveDefaultPreset(presetName, allFields);
		} else if (presetFields) {
			dispatch(setAllFields(presetFields)); // Dispatching setAllFields with the fields of the found preset

			// Extract and set the item number from the preset
			const itemNumberField = presetFields.find(field => field.startsWith("Item Numbers:"));
			if (itemNumberField) {
				const itemNumber = itemNumberField.split(': ')[1];
				dispatch(changeItemNumber(itemNumber)); // Update item number
				if (!showItemNumbers) {
					dispatch(toggleItemNumbersVisibility()); // Toggles visibility only if currently hidden
				}
			} else {
				if (showItemNumbers) {
					dispatch(toggleItemNumbersVisibility()); // Toggles visibility only if currently shown
				}

				dispatch(changeItemNumber('10')); // Reset item number to default if desired
			}

			handleSaveDefaultPreset(presetName, presetFields);
		}

		// Handle if custom preset input is already displayed and user selected another preset.
		if (dirty) {
			dispatch(setCustomPresetName(""));
			dispatch(setIsDirty(false));
		};
	};

	const handleToggleGroup = (groupName) => {
		// Dispatch an action to toggle all checkboxes for this group in your Redux store
		dispatch(toggleGroupSelection(groupName));
		if (!dirty) {
			dispatch(setCustomPresetName(determinePresetName()));
			dispatch(setSelectedPreset("none"));
			dispatch(setIsDirty(true));
		};
	};

	const renderGroupCheckbox = (groupName) => {
		// Determine whether all checkboxes in this group are selected
		const allSelected = fieldGroups[groupName].every(field => fields.includes(field));
		return (
			<Button
				disableElevation
				disableRipple
				variant={allSelected ? "contained" : "outlined"}
				color="primary"
				className="group-selection-button"
				onClick={() => handleToggleGroup(groupName)}
				sx={{ height: "100%" }}
			>
				{`${camelCaseToCapitalizedWords(groupName)}`}
			</Button>
		);
	};

	const renderCheckboxesForGroup = (groupName) => {
		return (
			<>
				{renderGroupCheckbox(groupName)}
				<Box>
					{fieldGroups[groupName].map((field) => {
						const isChecked = fields.includes(field);
						return (
							<FormControlLabel
								key={field}
								control={
									<Checkbox
										size="small"
										checked={isChecked}
										onChange={handleCheckboxChange}
										name={`${field}`} // Modify this if necessary to match how your fields are named in Redux
									/>
								}
								label={field}
							/>
						);
					})}
				</Box>
			</>
		);
	};

	const handleToggleItemNumbers = () => {
		dispatch(toggleItemNumbersVisibility());
		if (!dirty) {
			dispatch(setCustomPresetName(determinePresetName()));
			dispatch(setSelectedPreset("none"));
			dispatch(setIsDirty(true));
		};
	};

	const [highlightedIndex, setHighlightedIndex] = useState(-1);

	const moveItem = (direction, index) => {
		const newSelections = Array.from(fields);
		let newIndex = index;

		// Moving up
		if (direction === 'up' && index > 0) {
			[newSelections[index], newSelections[index - 1]] = [newSelections[index - 1], newSelections[index]];
			newIndex = index - 1;
		}
		// Moving down
		else if (direction === 'down' && index < newSelections.length - 1) {
			[newSelections[index], newSelections[index + 1]] = [newSelections[index + 1], newSelections[index]];
			newIndex = index + 1;
		}

		dispatch(reorderSelection(newSelections));

		setHighlightedIndex(newIndex); // Highlight the moved item
		// Remove highlight after some time
		setTimeout(() => setHighlightedIndex(-1), 2000);

		if (!dirty) {
			dispatch(setCustomPresetName(determinePresetName()));
			dispatch(setSelectedPreset("none"));
			dispatch(setIsDirty(true));
		};
	};

	const renderSelections = () => {
		return (
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
				maxHeight: selectionsHeight, //'calc(100vh - 70vh)', // Adjust based on your header, footer, and any other fixed-height elements
				overflowY: 'auto'
			}}>
				{/* Selections */}
				{fields.map((selection, index) => (
					<Box key={`${selection}-${index}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
						<Typography sx={{ width: '50px', mr: 2, textAlign: 'center' }}>{alphabet[index]}</Typography> {/* Adjusted width to match the 'Column' heading */}
						<Chip
							label={`${selection}`}
							sx={{
								flexGrow: 1,
								maxWidth: '60%',
								backgroundColor: highlightedIndex === index ? '#78c4dc' : 'default',
								color: highlightedIndex === index ? 'white' : 'default',
								// transition: 'background-color 300ms',
								// transitionDuration: '300ms',
							}}
						/>
						<Box sx={{ width: '120px', display: 'flex', justifyContent: 'center' }}> {/* Adjusted width to match the 'Change Order' heading */}
							<IconButton onClick={() => moveItem('up', index)} disabled={index === 0}>
								<ArrowUpwardIcon />
							</IconButton>
							<IconButton onClick={() => moveItem('down', index)} disabled={index === fields.length - 1}>
								<ArrowDownwardIcon />
							</IconButton>
						</Box>
					</Box>
				))}
			</Box>
		);
	};

	return (
		<Box >
			<Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
				{Object.keys(fieldGroups).map(groupName => (
					<Box key={groupName} className="settings-row">
						{renderCheckboxesForGroup(groupName)}
					</Box>
				))}
				<Box className="settings-row" >
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography variant="body1" sx={{ marginLeft: 2, marginRight: 2 }}>
							Item Numbers
						</Typography>
						<IconButton
							onClick={handleToggleItemNumbers}
							disableRipple
							sx={{ color: showItemNumbers ? 'primary.main' : 'grey', maxHeight: "42px" }}
						>
							{showItemNumbers ? <ToggleOnIcon sx={{ fontSize: "48px" }} /> : <ToggleOffIcon sx={{ fontSize: "48px" }} />}
						</IconButton>
					</Box>
					{showItemNumbers && (
						<RadioGroup
							row
							name="itemNumbers"
							value={selectedItemNumber}
							onChange={handleItemNumberChange}
						>
							{[...Array(10).keys()].map(number => (
								<FormControlLabel key={number} value={String(number + 1)} control={<Radio size="small" />} label={String(number + 1)} />
							))}
						</RadioGroup>
					)}
				</Box>

				<Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<TextField
							select
							label="Export File Type"
							value={exportFileType}
							onChange={handleExportFileTypeChange}
							size="medium"
							sx={{ width: 120 }}
							InputProps={{
								sx: {
									height: 40,
									'.MuiSelect-select': { // Targeting the select element specifically
										paddingTop: '10px', // Adjust padding to vertically center the text
										paddingBottom: '10px',
									}
								}
							}}
						>
							<MenuItem value=".xlsx">.XLSX</MenuItem>
						</TextField>
					</Box>
					<Box>
						<IconButton
							onClick={handleResetToDefault}
							sx={{ padding: "0", marginRight: "2rem" }}
						>
							<ResetIcon sx={{ fontSize: "2rem" }} />
						</IconButton>
						<TextField
							select
							label="Use Preset"
							value={selectedPreset}
							onChange={handleGetSelectedPreset}
							size="medium"
							className="custom-select-field"
							sx={{ width: 200, marginRight: "1rem" }}
							InputProps={{
								sx: {
									height: 40,
									'.MuiSelect-select': { // Targeting the select element specifically
										paddingTop: '10px', // Adjust padding to vertically center the text
										paddingBottom: '10px',
									}
								}
							}}
						>
							<MenuItem value="none">None</MenuItem>
							<MenuItem value="all-fields">All fields</MenuItem>
							{user.settings?.customPresets &&
								Object.keys(user.settings?.customPresets).map(presetName => (
									<MenuItem key={presetName} value={presetName} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										<span>{presetName}</span>
										<IconButton
											edge="end"
											size="small"
											onClick={(e) => {
												e.stopPropagation(); // Prevents the dropdown from closing
												handleDeletePreset(presetName);
											}}
										>
											<DeleteIcon fontSize="small" />
										</IconButton>
									</MenuItem>
								))}
						</TextField>
						{dirty &&
							<TextField
								error={
									customPresetName.trim() === "" ||
									(user.settings?.customPresets.hasOwnProperty(customPresetName) && !overwritePreset) ||
									reservedPresetNames.has(customPresetName.toLowerCase())
								}
								label="Custom Preset Name"
								value={customPresetName}
								onChange={handleCustomPresetNameChange}
								size="medium"
								sx={{ width: 180, marginRight: "1rem" }}  // Adjust width as needed
								InputLabelProps={{
									shrink: true,  // Keeps the label floated above
								}}
								InputProps={{
									sx: {
										height: 40,
									}
								}}
							/>
						}
						{(dirty && showOverwriteCheckBox) && (
							<FormControlLabel
								control={
									<Checkbox
										checked={overwritePreset}
										onChange={(e) => dispatch(setOverwritePreset(e.target.checked))}
										name="overwriteExisting"
									/>
								}
								label="Overwrite existing?"
							/>
						)}
						<Button
							disableElevation
							disableRipple
							disabled={!dirty || isSavingPreset}
							variant="contained"
							color="primary" // Or any color that suits your theme
							className="save-preset-button"
							onClick={handleSavePreset} // Implement this function to handle click event
							sx={{ height: '40px' }} // Adjust height to match the dropdown, if necessary
						>
							Save Preset
						</Button>
					</Box>
				</Box>
			</Paper >
			<Box >
				{/* Preview Heading */}
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mb: 1 }}>
					<Typography sx={{ width: '50px', mr: 2, textAlign: 'center' }} /> {/* Placeholder for aligning with the Column */}
					<Typography variant="h5" sx={{ flexGrow: 1, textAlign: 'center' }}>Preview</Typography> {/* Centered above Field */}
					<Typography sx={{ width: '120px' }} /> {/* Placeholder for aligning with Change Order */}
				</Box>
				{/* Headings */}
				<Box ref={headingRef} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mb: 1 }}>
					<Typography variant="button" sx={{ width: '50px', mr: 2, textAlign: 'center' }}>Column</Typography> {/* Adjusted width */}
					<Typography variant="button" sx={{ flexGrow: 1, textAlign: 'center' }}>Field</Typography>
					<Typography variant="button" sx={{ width: '120px', textAlign: 'center' }}>Change Order</Typography> {/* Adjusted width */}
				</Box>
				{renderSelections()}
			</Box>
		</Box >
	);
};

export default Fields;

function camelCaseToCapitalizedWords(str) {
	let spacedStr = str.replace(/([A-Z])/g, ' $1').trim();
	return spacedStr.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
}
