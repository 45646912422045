import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface BugReportModalState {
	active: boolean
}

// Define the initial state using that type
const initialState: BugReportModalState = {
	active: false,
}

export const bugReportModalSlice = createSlice({
	name: 'bugReportModal',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		updateState: (state, action: PayloadAction<boolean>) => {
			state.active = action.payload
		}
	}
})

export const { updateState } = bugReportModalSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectBugReportModalState = (state: RootState) => state.bugReportModal.active;

export default bugReportModalSlice.reducer