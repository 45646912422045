import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { startOfDay, endOfDay, subDays } from 'date-fns';
import type { RootState } from '../store'
import globalAxios from '../../globalAxios';

interface Meta {
	file: string;
	status: string;
	documentData: string;
	timestamp: string;
}

interface Log {
	_id: string;
	level: string;
	message: string;
	timestamp: string;
	meta: Meta;
}

interface User {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
}

// Define a type for the slice state
export interface LogState {
	logs: Log[];
	startDate: string;
	endDate: string;
	users: User[];
	status: 'idle' | 'loading' | 'succeeded' | 'failed';
	error: string | null;
}

// Define the initial state using that type
const initialState: LogState = {
	logs: [] as Log[],
	startDate: startOfDay(subDays(new Date(), 1)).toISOString(),
	endDate: endOfDay(new Date()).toISOString(),
	users: [],
	status: 'idle',
	error: null,
}

export const fetchUsers = createAsyncThunk('logs/fetchUsers', async () => {
	try {
		const response = await globalAxios.get('/users');
		return response.data;
	} catch (error) {
		throw new Error('Failed to fetch users');
	}
});

export const logsSlice = createSlice({
	name: 'logs',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		setLogs: (state, action: PayloadAction<Log[]>) => {
			state.logs = action.payload;
		},
		setStartDate: (state, action: PayloadAction<string>) => {
			state.startDate = action.payload;
		},
		setEndDate: (state, action: PayloadAction<string>) => {
			state.endDate = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUsers.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.users = action.payload;
			})
			.addCase(fetchUsers.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message || 'Failed to fetch users';
			});
	},
})

export const { setLogs, setStartDate, setEndDate } = logsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectLogsState = (state: RootState) => state.logs.logs;

export default logsSlice.reducer