import React from "react";
import {
	calculateFeeEstimate,
	calculateSinglePtFeeEstimate,
} from "./feeCalculation.ts";
import { determineSessionFundingStatus } from "./fundingStatus.ts";

export const contentToDisplay = (eventInfo, currentLens, schedules) => {
	const eProps = eventInfo.event.extendedProps;

	// Define checks for view types
	const isDayGridMonth = eventInfo.view.type === "dayGridMonth";
	const isTimeGridWeek = eventInfo.view.type === "timeGridWeek";
	const isTimeGridDay = eventInfo.view.type === "timeGridDay"; // Daily view
	const isListView = eventInfo.view.type === "listMonth"; // Adjust if using a different list view type

	const hospitalAcronym = eProps.hospital.acronym
		? eProps.hospital.acronym
		: abbreviateName(eProps.hospital.name);

	const surgeonAcronym = eProps.surgeon.acronym
		? eProps.surgeon.acronym
		: abbreviateName(
				eProps.surgeon.first_name + " " + eProps.surgeon.last_name
		  );

	const sessionFeeEstimate = calculateFeeEstimate(eProps.events, schedules);

	const timeGridDayTemplate = (
		<div className="event-multi-box">
			<div className="event-header">
				<div className="event-info">
					{eProps.startingTime.replace(":", "")} -
					<b>{` ${eProps.surgeon.first_name} ${eProps.surgeon.last_name}`}</b>{" "}
					- {eProps.hospital.name}
				</div>
				<div className="session-fee-estimate">
					{sessionFeeEstimate
						? `$${sessionFeeEstimate.toFixed(2)}`
						: ""}
				</div>
			</div>
			{eProps.events.map((subEvent, index) => (
				<div
					key={index}
					className="event-box"
					style={{
						height: `${100 / eProps.events.length}%`, // Dynamically set height based on number of events
					}}
				>
					<div>
						<b>
							{subEvent.patient.title}{" "}
							{subEvent.patient.first_name}{" "}
							{subEvent.patient.last_name}
						</b>
						{` ${subEvent.patient.date_of_birth}`}
					</div>
					<div>{subEvent.procedure}</div>
				</div>
			))}
		</div>
	);

	// Define the content to display conditionally
	switch (currentLens) {
		case "location":
			return {
				content: isDayGridMonth ? (
					`${
						formatMonthlyViewTime(eventInfo.timeText)
							? formatMonthlyViewTime(eventInfo.timeText) + " - "
							: ""
					}${hospitalAcronym} - ${surgeonAcronym}`
				) : isTimeGridWeek ? (
					<>
						{eventInfo.timeText} <br />
						{eProps.hospital.name} <br />
						{eProps.surgeon.first_name +
							" " +
							eProps.surgeon.last_name}
					</>
				) : isTimeGridDay ? (
					timeGridDayTemplate // Use the reusable template
				) : isListView ? (
					`${eventInfo.event.title} (${hospitalAcronym} - ${surgeonAcronym})`
				) : (
					`${eventInfo.timeText} ${eventInfo.event.title}`
				),
				backgroundColour: setEventColor("location", eProps),
			};
		case "uploadStatus":
			return {
				content: isDayGridMonth
					? `${hospitalAcronym} - ${surgeonAcronym}`
					: isTimeGridWeek
					? `${eventInfo.timeText} - ${hospitalAcronym}`
					: isTimeGridDay
					? timeGridDayTemplate // Use the reusable template
					: isListView
					? `${hospitalAcronym} - ${surgeonAcronym}`
					: `${eventInfo.timeText} ${eventInfo.event.title}`,
				backgroundColour: setEventColor("uploadStatus", eProps),
			};

		case "feeEstimate":
			const estimate = calculateFeeEstimate(eProps.events, schedules);

			return {
				content: estimate ? (
					isDayGridMonth ? (
						<>
							<b>${parseFloat(estimate.toFixed(2))}</b>,{" "}
							{eProps.events?.length}x Pt. - {surgeonAcronym}
						</>
					) : isTimeGridWeek ? (
						`${
							eventInfo.timeText
						} - ${surgeonAcronym} - Fee Estimate: $${estimate.toFixed(
							2
						)}`
					) : isTimeGridDay ? (
						timeGridDayTemplate // Use the reusable template
					) : isListView ? (
						<>
							<b>${parseFloat(estimate.toFixed(2))}</b>,{" "}
							{eProps.events?.length}x Pt. - {surgeonAcronym}
						</>
					) : (
						`$${estimate.toFixed(2)}, ${
							eProps.events?.length
						}x Pt. - ${surgeonAcronym}`
					)
				) : isDayGridMonth ? (
					`N/A, ${eProps.events?.length}x Pt. - ${surgeonAcronym}`
				) : (
					`N/A`
				),
				backgroundColour: setEventColor(
					"feeEstimate",
					eProps,
					schedules
				),
			};

		case "fundingStatus":
			const allCovered = determineSessionFundingStatus(
				eventInfo.event.extendedProps.events
			);

			return {
				content: isDayGridMonth
					? allCovered
						? `Funding Available - ${eProps.events?.length}x Pt.`
						: "Funding Missing"
					: isTimeGridWeek
					? `${eventInfo.timeText} - ${surgeonAcronym} - ${
							allCovered ? "Funding Available" : "Funding Missing"
					  }`
					: isTimeGridDay
					? timeGridDayTemplate // Use the reusable template
					: isListView
					? `Funding Status: ${allCovered ? "Available" : "Missing"}`
					: `${eventInfo.timeText} ${eventInfo.event.title}`,
				backgroundColour: setEventColor("fundingStatus", eProps),
			};

		default:
			return {
				content: isDayGridMonth
					? `${hospitalAcronym}`
					: isTimeGridWeek
					? `${eventInfo.timeText} - ${hospitalAcronym}`
					: isTimeGridDay
					? timeGridDayTemplate // Use the reusable template
					: isListView
					? `Event: ${eventInfo.event.title}`
					: `${eventInfo.timeText} ${eventInfo.event.title}`,
				backgroundColour: "grey",
			};
	}
};

// Formats the events into a uniform structure for use in rendering.
// Applies alias override here,
export const formatEvents = (view, eventsArray, aliases, schedules) => {
	const sortedEventsArray = [...eventsArray].sort((a, b) => {
		const dateA = new Date(a.date_of_service + "T" + a.start_time);
		const dateB = new Date(b.date_of_service + "T" + b.start_time);
		return dateA - dateB; // Sort by date first and then by time
	});

	return sortedEventsArray.map((event, index) => {
		const startDate = event.date_of_service;
		const startTime = event.start_time ? `T${event.start_time}:00` : ""; // Add time only if start_time is not null

		const hospitalAlias = aliases?.hospital_aliases?.find(
			(alias) => alias.name === event.hospital.name
		);
		const hospitalDisplayName = hospitalAlias
			? hospitalAlias.preferred_name
			: event.hospital.name;
		const hospitalAcronym = hospitalAlias?.preferred_acronym || "";

		// Add HF Aliases
		// Calculate Fee Estimate
		const processedEvents = event.events.map((subEvent) => {
			const feeEstimate = calculateSinglePtFeeEstimate(
				subEvent,
				schedules
			);

			// Check if subEvent has a health fund in patient.policies[0]
			const healthFund = subEvent.patient?.policies[0]?.healthFund;

			// Find the health fund alias by comparing healthFund.id with alias.id
			const healthFundAlias = healthFund
				? aliases?.healthfund_aliases?.find(
						(alias) => alias.healthfund_id === healthFund.id
				  )
				: null;

			// Use the preferred name and acronym if alias exists, otherwise use the original name
			const healthFundDisplayName = healthFundAlias
				? healthFundAlias.preferred_name
				: healthFund?.aliases[1] || "N/A";
			const healthFundAcronym = healthFundAlias?.preferred_acronym || "";

			// Return the modified subEvent, with healthFund set back in its original position
			return {
				...subEvent,
				feeEstimate,
				patient: {
					...subEvent.patient,
					policies: [
						{
							...subEvent.patient.policies[0],
							healthFund: {
								...healthFund,
								acronym: healthFundAcronym,
								name: healthFundDisplayName,
							},
						},
					],
				},
			};
		});
		// Check for surgeon alias
		const surgeonAlias = aliases?.surgeon_aliases?.find(
			(alias) =>
				alias.name ===
				`${event.surgeon.first_name} ${event.surgeon.last_name}`
		);
		const surgeonAcronym = surgeonAlias?.preferred_acronym || "";

		// Get the next event in the array to check for overlap
		const nextEvent = sortedEventsArray[index + 1];

		const formattedEvent = {
			id: event.id,
			title: toCapitalized(event.hospital.name),
			start: startDate + startTime,
			end:
				event.end ||
				calculateEndTime(startDate, event.start_time, nextEvent),
			surgeon: {
				acronym: surgeonAcronym,
				first_name: surgeonAlias
					? surgeonAlias.preferred_name.split(" ")[0]
					: event.surgeon.first_name,
				last_name: surgeonAlias
					? surgeonAlias.preferred_name.split(" ")[1]
					: event.surgeon.last_name,
			},
			hospital: {
				acronym: hospitalAcronym,
				name: hospitalDisplayName,
			},
			events: processedEvents,
			startingTime: event.start_time,
		};

		return {
			...formattedEvent,
			color: setEventColor(view, formattedEvent, schedules),
		};
	});
};

// Determine and set the event block colour.
const setEventColor = (view, e, schedules?) => {
	switch (view) {
		case "location":
			if (e.startingTime === null) {
				return "grey";
			}

			if (e.startingTime < "11:30") {
				return "#1976d2";
			} else if (e.startingTime > "11:30") {
				return "orange";
			} else {
				return "grey";
			}

		case "uploadStatus":
			return "#388e3c";

		case "feeEstimate":
			const estimate = calculateFeeEstimate(e.events, schedules);

			return estimate ? "#388e3c" : "grey";

		case "fundingStatus":
			const allCovered = determineSessionFundingStatus(e.events);

			return allCovered ? "#388e3c" : "grey";

		default:
			return "grey";
	}
};

const abbreviateName = (name) => {
	return name
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase())
		.join("");
};

// Convert month view time text to 24-hour format
const formatMonthlyViewTime = (timeText) => {
	if (!timeText) return "";
	const timeRegex = /^(\d{1,2}):?(\d{2})?(a|p)$/;
	const match = timeText.match(timeRegex);
	if (match) {
		let [hours, minutes = "00", period] = [match[1], match[2], match[3]];
		hours = parseInt(hours, 10);
		if (period === "p" && hours !== 12) {
			hours += 12;
		} else if (period === "a" && hours === 12) {
			hours = 0;
		}
		return `${hours.toString().padStart(2, "0")}${minutes}`;
	}
	return timeText; // Return as-is if not matched
};

const toCapitalized = (str) => {
	return str
		.split(" ")
		.map(
			(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		)
		.join(" ");
};

// Calculate end time for each calendar event to display block length in weekly view.
const calculateEndTime = (startDate, startTime, nextEvent) => {
	if (!startTime) {
		return null;
	}

	const startHour = parseInt(startTime.split(":")[0], 10);
	const startMinute = parseInt(startTime.split(":")[1], 10);
	const startDateTime = new Date(`${startDate}T${startTime}`);

	let defaultEndTime;

	// Determine the default end time based on start time ranges
	if (
		(startHour >= 6 && startHour < 11) ||
		(startHour === 11 && startMinute <= 30)
	) {
		defaultEndTime = new Date(`${startDate}T18:00:00`);
	} else if (
		(startHour >= 12 && startHour < 17) ||
		(startHour === 17 && startMinute <= 30)
	) {
		defaultEndTime = new Date(`${startDate}T22:00:00`);
	} else if (startHour >= 17 && startHour < 20) {
		defaultEndTime = new Date(`${startDate}T22:00:00`);
	} else {
		defaultEndTime = new Date(startDateTime);
	}

	// Check for overlap with the next event
	if (nextEvent) {
		const nextEventStartDateTime = new Date(
			`${nextEvent.date_of_service}T${nextEvent.start_time}:00`
		);
		if (defaultEndTime > nextEventStartDateTime) {
			// If the default end time causes an overlap, apply the overlap rules
			if (
				(startHour >= 6 && startHour < 11) ||
				(startHour === 11 && startMinute <= 30)
			) {
				defaultEndTime = new Date(`${startDate}T12:30:00`);
			} else if (
				(startHour >= 12 && startHour < 17) ||
				(startHour === 17 && startMinute <= 30)
			) {
				defaultEndTime = new Date(`${startDate}T17:30:00`);
			}
		}
	}

	// Convert UTC to local time directly using Date methods
	const localEndTime = new Date(
		defaultEndTime.getTime() - defaultEndTime.getTimezoneOffset() * 60000
	);

	return localEndTime.toISOString().slice(0, -1); // Remove the 'Z' to reflect local time without the UTC indicator
};
