import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface UploadState {
	uploadedFiles: FileList | null
}

// Define the initial state using that type
const initialState: UploadState = {
	uploadedFiles: null
}

export const uploadSlice = createSlice({
	name: 'upload',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		uploadFile: (state, action: PayloadAction<FileList | null>) => {
			state.uploadedFiles = action.payload
		}
	}
})

export const { uploadFile } = uploadSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectUpload = (state: RootState) => state.uploads.uploadedFiles

export default uploadSlice.reducer