import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
import type { RootState } from "../store";

// Define a type for the slice state
export interface CalendarState {
	events: Event[] | [];
	lens: View;
	view: string;
	currentDate: string;
	startDate: string;
	endDate: string;
}

// Define the initial state using that type
const initialState: CalendarState = {
	events: [],
	lens: "location",
	view: "dayGridMonth" as string,
	currentDate: new Date().toISOString(), // Track current date - so when user toggles back to calendar the last displayed page is retained.
	startDate: subDays(new Date(), 89).toISOString(),
	endDate: new Date().toISOString(),
};

export const calendarSlice = createSlice({
	name: "calendar",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setSessions: (state, action: PayloadAction<Event[] | []>) => {
			state.events = action.payload;
		},
		setCalendarLens(state, action) {
			state.lens = action.payload; // Update the view based on payload
		},
		// Track the currect view - monthly, weekly daily..
		setCalendarView(state, action) {
			state.view = action.payload;
		},
		setCalendarDate(state, action) {
			state.currentDate = action.payload;
		},
		// Use the PayloadAction type to declare the contents of `action.payload`
		updateEvents: (state, action: PayloadAction<Event[] | []>) => {
			state.events = [...state.events, ...action.payload];
		},
		removeSession: (state, action: PayloadAction<number>) => {
			state.events = state.events.filter(
				(event) => event.id !== action.payload
			);
		},
		setStartDate: (state, action: PayloadAction<string>) => {
			state.startDate = action.payload;
		},
		setEndDate: (state, action: PayloadAction<string>) => {
			state.endDate = action.payload;
		},
		deleteEventsByMonth: (state, action: PayloadAction<string>) => {
			const [year, month] = action.payload.split("-");
			state.events = state.events.filter((event) => {
				const [eventYear, eventMonth] =
					event.date_of_service.split("-");
				return !(eventYear === year && eventMonth === month);
			});
		},
	},
});

export const {
	setSessions,
	setCalendarLens,
	setCalendarView,
	setCalendarDate,
	updateEvents,
	removeSession,
	setStartDate,
	setEndDate,
	deleteEventsByMonth,
} = calendarSlice.actions;

// Export selectors.
export const selectCalendarEvents = (state: RootState) => state.calendar.events;
export const selectStartDate = (state: RootState) => state.calendar.startDate;
export const selectEndDate = (state: RootState) => state.calendar.endDate;
export const selectCalendarView = (state: RootState) => state.calendar.view;

export default calendarSlice.reducer;

type View =
	| "location"
	| "uploadStatus"
	| "feeEstimate"
	| "fundingStatus"
	| string; // Allows for future extensibility

interface Event {
	id: number;
	title: string;
	start: string;
}
