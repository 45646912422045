import { useEffect } from "react";
import jwtCheck from "./jwtCheck.js";
import SideBar from "./components/sidebar/SideBar.tsx";
import GlobalSnackbar from "./components/snackbar/GlobalSnackbar.tsx";

import "./App.css";

function App() {

	useEffect(() => {
		// Perform the first JWT check immediately
		if (!jwtCheck()) {
			window.location = '/login';
		};
		// Then set up the interval for subsequent checks every 40 seconds
		const intervalId = setInterval(() => {
			if (!jwtCheck()) {
				window.location = '/login';
			}
		}, 15 * 60000); // Check every 15 mins
		return () => clearInterval(intervalId); // Clear the interval on component unmount
	}, []);

	return (
		<>
			<SideBar />
			<GlobalSnackbar />
		</>
	);
}

export default App;
