import React, { useEffect, useState } from "react";
import globalAxios from "../../globalAxios";
import { useAppSelector, useAppDispatch } from "../../store/hooks.ts";
import {
	setLogs,
	setStartDate,
	setEndDate,
	fetchUsers,
} from "../../store/slices/logsSlice.ts";

import {
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	TextField,
	MenuItem,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { enAU } from "date-fns/locale";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import {
	PictureAsPdf as PdfIcon,
	Description as DocxIcon,
} from "@mui/icons-material";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./Admin.css";

const iconSize = 24;

const Admin = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const logs = useAppSelector((state) => state.logs.logs);
	const startDate = new Date(useAppSelector((state) => state.logs.startDate));
	const endDate = new Date(useAppSelector((state) => state.logs.endDate));

	const dispatch = useAppDispatch();

	// Function to fetch logs and prepare them for download
	const fetchLogs = async () => {
		setLoading(true);

		try {
			const response = await globalAxios.get("/logs", {
				params: {
					startDate,
					endDate,
					selectedUserId: selectedUser,
				},
			}); // Make the API request
			const logs = response.data; // Access the logs from the response data

			// Process logs to convert Base64 to a blob and create download links
			const processedLogs = logs.map((log) => {
				if (log.meta.documentData && log.meta.file) {
					const extension = log.meta.file.split(".").pop();
					const mimeType = getMimeType(extension);
					const downloadLink = createDownloadLink(
						log.meta.documentData,
						log.meta.file,
						mimeType
					);

					return { ...log, downloadLink }; // Append a download link to the log object
				}

				return log; // Return log as is if there's no documentData
			});

			dispatch(setLogs(processedLogs)); // Update state with processed logs
			if (error) setError("");
		} catch (error) {
			setError("Failed to fetch data");
			console.error("Failed to fetch logs:", error);
		}
		setLoading(false);
	};

	const handleFromDateChange = (date) => {
		const startDate = new Date(date);
		startDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000 UTC
		dispatch(setStartDate(startDate.toISOString()));
	};

	const handleToDateChange = (date) => {
		const endDate = new Date(date);
		endDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999 UTC
		dispatch(setEndDate(endDate.toISOString()));
	};

	const [selectedUser, setSelectedUser] = useState(0);
	const users = useAppSelector((state) => state.logs.users);
	// const usersStatus = useAppSelector((state) => state.logs.status);
	// const usersError = useAppSelector((state) => state.logs.error);

	const handleSelectedUserChange = (event) => {
		setSelectedUser(event.target.value);
	};

	useEffect(() => {
		// Dispatch fetchUsers action when component mounts
		dispatch(fetchUsers());
	}, [dispatch]);

	// State to track expanded panels as an array
	const [expandedPanels, setExpandedPanels] = useState([]);

	const handleAccordionChange = (panel) => (event, isExpanded) => {
		if (isExpanded) {
			// Add panel to array if expanded
			setExpandedPanels((prev) => [...prev, panel]);
		} else {
			// Remove panel from array if not expanded
			setExpandedPanels((prev) => prev.filter((item) => item !== panel));
		}
	};

	return (
		<div>
			<h1>Admin Panel</h1>
			<LocalizationProvider
				dateAdapter={AdapterDateFns}
				adapterLocale={enAU}
			>
				<Box
					sx={{
						border: "1px solid #ccc",
						padding: "1rem",
						marginBottom: "1rem",
					}}
				>
					<Typography variant="h6" gutterBottom>
						Logs
					</Typography>
					<div
						style={{
							display: "flex",
							gap: "1rem",
							marginBottom: "1rem",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={fetchLogs}
							disabled={loading}
							disableElevation
						>
							{loading ? "Loading..." : "Fetch Data"}
						</Button>
						<DesktopDatePicker
							label="From"
							inputFormat="dd/MM/yyyy"
							maxDate={endDate}
							value={startDate}
							onChange={handleFromDateChange}
							slotProps={{ textField: { size: "small" } }}
							renderInput={(params) => <TextField {...params} />}
						/>
						<DesktopDatePicker
							label="To"
							inputFormat="dd/MM/yyyy"
							minDate={startDate}
							value={endDate}
							onChange={handleToDateChange}
							slotProps={{ textField: { size: "small" } }}
							renderInput={(params) => <TextField {...params} />}
						/>
						<TextField
							select
							size="small"
							label="Users"
							value={selectedUser} // Ensure this state is managed appropriately
							onChange={handleSelectedUserChange} // Implement this function to update date range
							sx={{ width: 240 }} // Adjust the width as necessary to match other inputs
						>
							<MenuItem key={1} value={0}>
								All
							</MenuItem>
							{users.map((user) => (
								<MenuItem key={user.id} value={user.id}>
									{user.first_name} {user.last_name}
								</MenuItem>
							))}
						</TextField>
					</div>
					{error && <p style={{ color: "red" }}>{error}</p>}
					{Boolean(logs.length) && (
						<Box display="flex" mt={2} mb={2}>
							<Typography mr={2}>
								Total Files: {logs.length}
							</Typography>
							<Typography mr={2} color="success.main">
								Success:{" "}
								{
									logs.filter(
										(log) =>
											log.meta &&
											log.meta.status === "success"
									).length
								}
							</Typography>
							<Typography mr={2} color="error.main">
								Fails:{" "}
								{
									logs.filter(
										(log) =>
											log.meta &&
											log.meta.status === "failed"
									).length
								}
							</Typography>
						</Box>
					)}
					<div>
						<div style={{ width: "100%" }}>
							{logs.map(
								(
									{
										message,
										downloadLink,
										meta: { file, status, userId },
									},
									index
								) => {
									// Determine file extension
									if (!file) {
										return <></>;
									}

									const fileExtension = file
										.split(".")
										.pop()
										.toLowerCase();
									// Set icon based on file extension
									let iconComponent;
									if (fileExtension === "pdf") {
										iconComponent = (
											<PdfIcon
												sx={{
													fontSize: iconSize,
													color: "#FF0000",
												}}
											/>
										);
									} else if (fileExtension === "docx") {
										iconComponent = (
											<DocxIcon
												sx={{
													fontSize: iconSize,
													color: "#4472C4",
												}}
											/>
										);
									} else {
										// Default icon if extension not matched
										iconComponent = (
											<InsertDriveFileIcon
												sx={{ fontSize: iconSize }}
											/>
										);
									}

									return (
										<Accordion
											key={index}
											elevation={0}
											square
											expanded={expandedPanels.includes(
												`panel${index}`
											)}
											onChange={handleAccordionChange(
												`panel${index}`
											)}
											sx={(theme) => ({
												// Your existing styling logic here
												border: `1px solid ${theme.palette.divider}`,
												borderBottom: "none",
												"&:before": {
													display: "none",
												},
												...(expandedPanels.includes(
													`panel${index}`
												) && {
													borderBottom: `1px solid ${theme.palette.divider}`,
												}),
												...(expandedPanels.includes(
													`panel${index + 1}`
												) && {
													borderBottom: `1px solid ${theme.palette.divider}`,
												}),
												"&:last-of-type": {
													borderBottom: `1px solid ${theme.palette.divider}`,
												},
											})}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls={`panel${index}bh-content`}
												id={`panel${index}bh-header`}
												sx={{
													justifyContent:
														"space-between", // Push icons to the right
												}}
											>
												<Typography
													sx={{
														width: "100%",
														display: "flex",
														alignItems: "center",
														gap: 1,
													}}
												>
													{status === "success" ? (
														<CheckIcon color="success" />
													) : (
														<CloseIcon color="error" />
													)}
													{file}
												</Typography>
												<Typography
													sx={{ marginRight: "2rem" }}
												>
													{
														users.filter(
															(user) =>
																user.id ===
																userId
														)[0].first_name
													}
												</Typography>
												{downloadLink && (
													<>
														{!file
															.toLowerCase()
															.endsWith(
																".docx"
															) ? (
															<a
																href={
																	downloadLink.url
																}
																target="_blank"
																rel="noopener noreferrer"
																className="preview-icon"
																style={{
																	marginRight:
																		"1rem",
																	display:
																		"flex",
																	alignItems:
																		"center",
																}}
																onClick={(e) =>
																	e.stopPropagation()
																}
															>
																{iconComponent}{" "}
																{/* Assuming PreviewIcon is imported */}
															</a>
														) : (
															<div
																className="preview-icon-placeholder"
																style={{
																	marginRight:
																		"1rem",
																	display:
																		"flex",
																	alignItems:
																		"center",
																	width:
																		iconSize +
																		4, // Assuming the size of iconComponent
																}}
															/>
														)}
														<a
															href={
																downloadLink.url
															}
															download={
																downloadLink.filename
															}
															className="download-icon"
															style={{
																marginRight:
																	"2rem",
																display: "flex",
																alignItems:
																	"center",
															}}
															onClick={(e) =>
																e.stopPropagation()
															}
														>
															<DownloadIcon
																sx={{
																	fontSize:
																		iconSize,
																}}
															/>{" "}
															{/* Assuming DownloadIcon is imported */}
														</a>
													</>
												)}
											</AccordionSummary>
											<AccordionDetails>
												<Typography
													color={
														status === "success"
															? "green"
															: "red"
													}
												>
													Status: {status}
													{message &&
														` - Message: ${message}`}
												</Typography>
											</AccordionDetails>
										</Accordion>
									);
								}
							)}
						</div>
					</div>
				</Box>
			</LocalizationProvider>
		</div>
	);
};

export default Admin;

const createDownloadLink = (base64Data, filename, contentType) => {
	const blob = base64ToBlob(base64Data, contentType);
	const url = URL.createObjectURL(blob);
	return { url, filename };
};

const base64ToBlob = (base64, contentType) => {
	const byteCharacters = atob(base64);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += 512) {
		const slice = byteCharacters.slice(offset, offset + 512);
		const byteNumbers = slice.split("").map((char) => char.charCodeAt(0));
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	return new Blob(byteArrays, { type: contentType });
};

const getMimeType = (extension) => {
	const mimeTypes = {
		pdf: "application/pdf",
		docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		txt: "text/plain",
		// Add more mappings as necessary
	};
	return mimeTypes[extension.toLowerCase()] || "application/octet-stream"; // Default to binary type if unknown
};
