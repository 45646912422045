import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { enAU } from "date-fns/locale";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import { useAppDispatch, useAppSelector } from "../../../store/hooks.ts";
import { setCalendarDate } from "../../../store/slices/calendarSlice.ts";

const MiniCalendar = () => {
	const currentDate = useAppSelector((state) => state.calendar.currentDate);
	const dispatch = useAppDispatch();

	// // Handle date change when a date is clicked
	// const handleDateChange = (newDate) => {
	// 	dispatch(setCalendarDate(newDate.toISOString()));
	// };

	const handleDateChange = (newDate) => {
		if (newDate) {
			// Convert currentDate to a Date object if it exists
			const parsedCurrentDate = currentDate
				? new Date(currentDate)
				: null;

			// Check if the day or month has changed
			const dayChanged =
				!parsedCurrentDate ||
				newDate.getDate() !== parsedCurrentDate.getDate();
			const monthChanged =
				!parsedCurrentDate ||
				newDate.getMonth() !== parsedCurrentDate.getMonth();

			// Only dispatch if the day or month has changed
			if (dayChanged || monthChanged) {
				dispatch(setCalendarDate(newDate.toISOString()));
			}
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
			{/* Use 'value' for controlled component behaviour */}
			<DateCalendar
				value={new Date(currentDate)}
				onChange={handleDateChange}
			/>
		</LocalizationProvider>
	);
};

export default MiniCalendar;
