// User slice for managing user details
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface UserState {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	admin: boolean | null;
	settings: Record<string, any> | null; // Define settings as a flexible JSON object
}

// Define the initial state using that type
const initialState: UserState = {
	id: 0,
	firstName: "",
	lastName: "",
	email: "",
	admin: null,
	settings: null,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		// Action to set the user details
		setUser: (state, action: PayloadAction<UserState>) => {
			state.id = action.payload.id;
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.email = action.payload.email;
			state.admin = action.payload.admin;
			state.settings = action.payload.settings;
		},
		updateCustomPresets: (
			state,
			action: PayloadAction<{ name: string; fields: string[] }>
		) => {
			if (!state.settings) {
				state.settings = { customPresets: {} };
			} else if (!state.settings.customPresets) {
				state.settings.customPresets = {};
			}
			state.settings.customPresets[action.payload.name] =
				action.payload.fields;
		},
		deleteCustomPreset: (state, action: PayloadAction<string>) => {
			if (state.settings && state.settings.customPresets) {
				delete state.settings.customPresets[action.payload];
			}
		},
		updateDefaultPreset: (
			state,
			action: PayloadAction<{ name: string; fields: string[] }>
		) => {
			if (!state.settings) {
				state.settings = { defaultPreset: {} };
			} else if (!state.settings.defaultPreset) {
				state.settings.defaultPreset = {};
			}
			state.settings.defaultPreset.name = action.payload.name;
			state.settings.defaultPreset.fields = action.payload.fields;
		},
		//  Ad Alias from search.
		addAlias: (
			state,
			action: PayloadAction<{
				aliasType: "Hospital" | "Surgeon" | "Health Fund" | string;
				alias: {
					aliasId: number;
					name: string;
					preferredName: string;
					preferredAcronym: string;
				};
			}>
		) => {
			// Initialize settings and aliases if not already initialized
			if (!state.settings) {
				state.settings = { aliases: {} };
			} else if (!state.settings.aliases) {
				state.settings.aliases = {};
			}

			// Helper function to add a new alias
			const addNewAlias = (aliasList, newAlias, aliasIdKey) => {
				const aliasId = newAlias.aliasId;

				const formattedAlias = {
					[aliasIdKey]: aliasId,
					name: newAlias.name,
					preferred_name: newAlias.preferredName,
					preferred_acronym: newAlias.preferredAcronym,
				};

				aliasList.push(formattedAlias); // Add new alias
			};

			// Handle different alias types
			switch (action.payload.aliasType) {
				case "Hospital":
					if (!state.settings.aliases.hospital_aliases) {
						state.settings.aliases.hospital_aliases = [];
					}
					addNewAlias(
						state.settings.aliases.hospital_aliases,
						action.payload.alias,
						"hospital_id"
					);
					break;

				case "Surgeon":
					if (!state.settings.aliases.surgeon_aliases) {
						state.settings.aliases.surgeon_aliases = [];
					}
					addNewAlias(
						state.settings.aliases.surgeon_aliases,
						action.payload.alias,
						"surgeon_id"
					);
					break;

				case "Health Fund":
					if (!state.settings.aliases.healthfund_aliases) {
						state.settings.aliases.healthfund_aliases = [];
					}
					addNewAlias(
						state.settings.aliases.healthfund_aliases,
						action.payload.alias,
						"healthfund_id"
					);
					break;

				default:
					break;
			}
		},
		updateAlias: (
			state,
			action: PayloadAction<{
				aliasType: "Hospital" | "Surgeon" | "Health Fund";
				alias: {
					aliasId: number;
					name: string;
					preferred_name: string;
					preferred_acronym: string;
				};
			}>
		) => {
			// Initialize settings and aliases if not already initialized
			if (!state.settings) {
				state.settings = { aliases: {} };
			} else if (!state.settings.aliases) {
				state.settings.aliases = {};
			}

			// Helper function to update an existing alias
			const updateExistingAlias = (
				aliasList,
				updatedAlias,
				aliasIdKey
			) => {
				const aliasId = updatedAlias[aliasIdKey];
				const aliasIndex = aliasList.findIndex(
					(alias) => alias[aliasIdKey] === aliasId
				);

				const formattedAlias = {
					[aliasIdKey]: aliasId,
					name: updatedAlias.name,
					preferred_name: updatedAlias.preferred_name,
					preferred_acronym: updatedAlias.preferred_acronym,
				};

				if (aliasIndex !== -1) {
					// Update existing alias
					aliasList[aliasIndex] = formattedAlias;
				} else {
					console.error("Alias not found, cannot update");
				}
			};

			// Handle different alias types
			switch (action.payload.aliasType) {
				case "Hospital":
					if (!state.settings.aliases.hospital_aliases) {
						console.error("Hospital aliases not found");
						return;
					}
					updateExistingAlias(
						state.settings.aliases.hospital_aliases,
						action.payload.alias,
						"hospital_id"
					);
					break;

				case "Surgeon":
					if (!state.settings.aliases.surgeon_aliases) {
						console.error("Surgeon aliases not found");
						return;
					}
					updateExistingAlias(
						state.settings.aliases.surgeon_aliases,
						action.payload.alias,
						"surgeon_id"
					);
					break;

				case "Health Fund":
					if (!state.settings.aliases.healthfund_aliases) {
						console.error("Health Fund aliases not found");
						return;
					}
					updateExistingAlias(
						state.settings.aliases.healthfund_aliases,
						action.payload.alias,
						"healthfund_id"
					);
					break;

				default:
					break;
			}
		},
		// Optional: Remove alias if needed
		removeAlias: (
			state,
			action: PayloadAction<{
				type: "hospital" | "surgeon" | "healthfund";
				id: number;
			}>
		) => {
			if (state.settings && state.settings.aliases) {
				switch (action.payload.type) {
					case "hospital":
						state.settings.aliases.hospital_aliases =
							state.settings.aliases.hospital_aliases.filter(
								(alias: any) =>
									alias.hospital_id !== action.payload.id
							);
						break;
					case "surgeon":
						state.settings.aliases.surgeon_aliases =
							state.settings.aliases.surgeon_aliases.filter(
								(alias: any) =>
									alias.surgeon_id !== action.payload.id
							);
						break;
					case "healthfund":
						state.settings.aliases.healthfund_aliases =
							state.settings.aliases.healthfund_aliases.filter(
								(alias: any) =>
									alias.healthfund_id !== action.payload.id
							);
						break;
					default:
						break;
				}
			}
		},
	},
});

export const {
	setUser,
	updateCustomPresets,
	deleteCustomPreset,
	updateDefaultPreset,
	addAlias,
	updateAlias,
	removeAlias,
} = userSlice.actions;

// Selector to get user state
export const selectUserState = (state: RootState) => state.user;

export default userSlice.reducer;
