import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Skeleton,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import globalAxios from "../../../globalAxios.js";

import { SERVER_URL } from "../../../config.js";

import { useAppSelector, useAppDispatch } from "../../../store/hooks.ts"; // Assuming you have this set up
import {
	aliasMapping,
	selectAliasesTab,
} from "../../../store/slices/settingsSlice.ts";
import { addAlias } from "../../../store/slices/userSlice.ts";

const Search = ({ miniNavRef }) => {
	const dispatch = useAppDispatch();

	// State to hold the search input
	const [searchTerm, setSearchTerm] = useState("");
	const [loading, setLoading] = useState(false);

	const [searchPerformed, setSearchPerformed] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [error, setError] = useState("");

	const aliasesTab = useAppSelector(selectAliasesTab);
	// Get the alias name dynamically based on the current tab index
	const aliasType = aliasMapping[aliasesTab] || "unknown";

	const resultsRef = useRef(null); // Reference for the search heading
	const listRef = useRef(null);
	const [containerHeight, setContainerHeight] = useState("auto");
	const [shouldScroll, setShouldScroll] = useState(false);

	// Dynamically set the container height based on available space
	useEffect(() => {
		const updateContainerHeight = () => {
			let resultsBottom = 0;
			let miniNavBottom = window.innerHeight; // Default in case ref is unavailable

			// Get the bottom of the miniNav container if the ref exists
			if (miniNavRef.current) {
				const miniNavRect = miniNavRef.current.getBoundingClientRect();
				miniNavBottom = miniNavRect.bottom;
			}

			// Get the bottom of the "results" text
			if (resultsRef.current) {
				const { bottom } = resultsRef.current.getBoundingClientRect();
				resultsBottom = bottom;
			}

			// Calculate the height between the bottom of the "results" and the bottom of the miniNav
			const availableHeight = miniNavBottom - resultsBottom - 20; // Subtract some padding if needed
			setContainerHeight(`${availableHeight}px`);

			// Determine if scrolling is needed
			if (
				listRef.current &&
				listRef.current.scrollHeight > availableHeight
			) {
				setShouldScroll(true);
			} else {
				setShouldScroll(false);
			}
		};

		updateContainerHeight();
		window.addEventListener("resize", updateContainerHeight);
		return () =>
			window.removeEventListener("resize", updateContainerHeight);
	}, [miniNavRef, searchResults]);

	// Reset the search when alias type (tab) changes
	useEffect(() => {
		// Clear the search results, search term, and error when the alias tab changes
		setSearchTerm("");
		setSearchResults([]);
		setError("");
		setSearchPerformed(false);
	}, [aliasesTab]);

	// Handler to update the search term when the input changes
	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleSearch();
		}
	};

	const handleSearch = async () => {
		if (!searchTerm) {
			setError("Please enter a search term");
			return;
		}

		if (aliasMapping[aliasesTab] === "Other") {
			setError("Please enter a search term");
			return;
		}

		setError("");
		setLoading(true);
		setSearchPerformed(true);

		try {
			const response = await globalAxios.post(
				`${SERVER_URL}/search`, // Adjust the URL to your backend search endpoint
				{
					searchTerm, // The search term entered by the user
					aliasType, // The current alias tab (hospital, healthfund, etc.)
				}
			);
			console.log("Search Results:", response.data.results); // Process the search results
			setSearchResults(response.data.results);

			// Do something with the response, such as displaying the results or saving them in state
		} catch (error) {
			console.error("Error performing search:", error);
			setError(
				"There was an error performing the search. Please try again."
			);
		} finally {
			// Stop loading
			setLoading(false);
		}
	};

	const renderSkeletons = () => {
		return Array.from({ length: 5 }).map((_, index) => (
			<ListItem key={index} divider>
				<Skeleton variant="text" width="100%" height={30} />
				<IconButton disabled edge="end" aria-label="add">
					<AddIcon />
				</IconButton>
			</ListItem>
		));
	};

	const getText = (result) => {
		switch (aliasType) {
			case "Hospital":
				return {
					primary: result.name || result.title || "No name available",
					secondary: `Network: ${
						result.network?.toUpperCase() || "UNKNOWN"
					}`, // Adjust based on result data structure
				};
			case "Health Fund":
				return {
					primary: result.aliases?.[1] || "No alias available",
					secondary: `Type: ${result.type}`, // Adjust based on result data structure
				};
			case "Surgeon":
				return {
					primary:
						result.first_name + " " + result.last_name ||
						"No name available",
					secondary: `Specialty: ${result.specialty || "UNKNOWN"}`, // Adjust as necessary
				};
			default:
				return {
					primary: result.name || "No name available",
					secondary: `ID: ${result.id}`, // Fallback for other types
				};
		}
	};

	const handleAddAlias = async (result) => {
		try {
			let preferredName;

			if (aliasType === "Hospital") {
				preferredName = result.name; // Use 'name' or fallback
			} else if (aliasType === "Health Fund") {
				preferredName = result.aliases[1]; // Use alias, or fallback
			} else if (aliasType === "Surgeon") {
				preferredName = result.first_name + " " + result.last_name; // Full name or fallback
			}

			const response = await globalAxios.post(
				`${SERVER_URL}/user/settings/alias`, // Adjust the URL to your backend route for adding aliases
				{
					aliasType,
					aliasId: result.id, // The selected result's ID
					preferredName, // Dynamically use name fields
					preferredAcronym: result.acronym || "", // Adjust as necessary
				}
			);

			// Check if the response status is successful (200 or 201)
			if (response.status === 200 || response.status === 201) {
				// Use the result from the response to update Redux state
				dispatch(
					addAlias({
						aliasType,
						alias: {
							aliasId: result.id,
							name: preferredName,
							preferredName,
							preferredAcronym: result.acronym || "",
						},
					})
				);
			} else {
				console.error("Failed to add alias. Status:", response.status);
				setError("Failed to add the alias. Please try again.");
			}
		} catch (error) {
			console.error("Error adding alias:", error);
			setError("There was an error adding the alias. Please try again.");
		}
	};

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					width: "100%",
					mb: 2,
				}}
			>
				<Typography
					variant="body1"
					fontWeight={600}
					sx={{ textAlign: "center", flexGrow: 1 }}
				>
					Search {aliasType}
				</Typography>
				<Tooltip
					title={
						<>
							{`Search to add a ${aliasType}. Then map an alias name and acronym that will be used instead.`}
							<br />
							<br />
							{`These changes will be reflected on your calendar, and in your exported files.`}
						</>
					}
					placement="right"
					arrow
				>
					<IconButton>
						<InfoOutlinedIcon color="primary" />
					</IconButton>
				</Tooltip>
			</Box>

			{/* Search bar */}
			<TextField
				label="Search"
				variant="outlined"
				size="small"
				value={searchTerm}
				onChange={handleSearchChange}
				onKeyDown={handleKeyDown}
				sx={{ width: "100%", minWidth: "100%" }}
				margin="normal"
			/>
			{/* Optional Button to trigger the search */}
			<Button
				disableElevation
				disableRipple
				variant="contained"
				color="primary"
				sx={{ width: "100%", minWidth: "100%" }}
				onClick={handleSearch}
			>
				{loading ? "Searching..." : "Search"}
			</Button>

			<Typography
				ref={resultsRef}
				variant="body2"
				color="textSecondary"
				gutterBottom
				sx={{ minHeight: "1.5rem", textAlign: "center" }} // Ensures this section always occupies space
			>
				{loading
					? ""
					: searchResults.length > 0
					? `${searchResults.length} ${
							searchResults.length === 1 ? "result" : "results"
					  } found`
					: searchPerformed
					? "No results found"
					: ""}
				{/* Leave blank before search */}
			</Typography>

			{/* Scrollable search results */}
			<Box
				ref={listRef}
				sx={{
					maxHeight: containerHeight,
					overflowY: shouldScroll ? "scroll" : "visible", // Enable vertical scrolling
					marginBottom: "1rem", // Add some space below the results
				}}
			>
				<List>
					{loading
						? renderSkeletons() // Show skeletons if loading
						: searchResults.map((result, index) => {
								const { primary, secondary } = getText(result);
								return (
									<ListItem key={index} divider>
										<ListItemText
											primary={primary}
											secondary={secondary}
										/>
										<IconButton
											onClick={() =>
												handleAddAlias(result)
											}
											edge="end"
											aria-label="add"
										>
											<AddIcon />
										</IconButton>
									</ListItem>
								);
						  })}
				</List>
			</Box>

			{/* Display message if no results after search */}
			{searchPerformed && searchResults.length === 0 && !loading && (
				<Typography variant="body2" color="textSecondary">
					No results found.
				</Typography>
			)}

			{/* Display error message */}
			{error && (
				<Typography variant="body2" color="error">
					{error}
				</Typography>
			)}
		</Box>
	);
};

export default Search;
