import React, { useEffect, useRef, useState } from "react";
import globalAxios from "../../globalAxios.js";
import { SERVER_URL } from "../../config.js";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import { useAppSelector, useAppDispatch } from "../../store/hooks.ts";
import {
	setCalendarView,
	setCalendarDate,
	removeSession,
	deleteEventsByMonth,
} from "../../store/slices/calendarSlice.ts";

import { Box, Button, CircularProgress, Grid } from "@mui/material";

import "./Calendar.css";
import { contentToDisplay, formatEvents } from "./helper/eventRendering.tsx";
import CalendarDialog from "./calendardialog/CalendarDialog.tsx";

const Calendar = () => {
	const calendarRef = useRef(null);

	const [open, setOpen] = useState(false);
	const [deleting, setDeleting] = useState(false); // for single event.
	const [deletingEvents, setDeletingEvents] = useState(false); // for current month of events.

	const [selectedEvent, setSelectedEvent] = useState({
		title: "",
		id: "",
		start: new Date(),
		surgeon: "",
		hospital: "",
		events: [],
	});

	const events = useAppSelector((state) => state.calendar.events);
	const currentLens = useAppSelector((state) => state.calendar.lens);
	const currentView = useAppSelector((state) => state.calendar.view);
	const currentDate = useAppSelector((state) => state.calendar.currentDate);
	const isAdmin = useAppSelector((state) => state.user.admin);
	const aliases = useAppSelector((state) => state.user.settings?.aliases);

	const [schedules, setSchedules] = useState([]);
	const extractItemNumbersFromRedux = (events) => {
		const itemNumbers = [];

		events.forEach((event) => {
			event.events.forEach((subEvent) => {
				try {
					// Skip if actual_items is null, undefined, or the string "null"
					if (
						!subEvent.actual_items ||
						subEvent.actual_items === "null"
					) {
						return;
					}

					// Parse actual_items which is in JSON string format
					const parsedItems = JSON.parse(subEvent.actual_items);

					// Skip if parsedItems is not an array (safety check)
					if (!Array.isArray(parsedItems)) {
						return;
					}

					// Filter only numeric items
					const numericItems = parsedItems.filter(
						(item) => !isNaN(item)
					);

					// Collect only numeric item numbers
					itemNumbers.push(...numericItems);
				} catch (error) {
					console.error("Error parsing actual_items:", error);
				}
			});
		});

		// Return unique item numbers
		return [...new Set(itemNumbers)];
	};
	const fetchSchedules = async (itemNumbers) => {
		if (itemNumbers.length === 0) return; // Don't fetch if no item numbers

		try {
			const response = await globalAxios.get(`${SERVER_URL}/schedules`, {
				params: { itemNums: itemNumbers },
			});

			setSchedules(response.data); // Store the fetched schedules
		} catch (error) {
			console.error("Error fetching schedules:", error);
		}
	};

	// Call this after extracting item numbers from Redux
	useEffect(() => {
		const itemNumbers = extractItemNumbersFromRedux(events); // Use the events from Redux
		fetchSchedules(itemNumbers);
	}, [events]); // Re-run when Redux events change

	const dispatch = useAppDispatch();

	const handleEventClick = (clickInfo) => {
		const { title, id, start, extendedProps } = clickInfo.event;

		setSelectedEvent({
			title: title,
			id: id,
			start: start,
			surgeon:
				extendedProps.surgeon.first_name +
				" " +
				extendedProps.surgeon.last_name,
			hospital: extendedProps.hospital.name,
			events: extendedProps.events,
		});

		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedEvent({
			title: "",
			id: "",
			start: new Date(),
			surgeon: "",
			hospital: "",
			events: [],
		});
	};

	// const handleEdit = () => {
	// 	console.log('Editing event:', selectedEvent);
	// 	// Implement your edit logic here
	// 	setOpen(false);
	// };

	const handleDelete = async () => {
		console.log("Deleting event:", selectedEvent);

		if (!selectedEvent.id) {
			console.error("No event selected or missing event ID.");
			return;
		}

		setDeleting(true);

		try {
			// Replace `/api/sessions/${selectedEvent.id}` with your actual endpoint that handles session deletion
			const response = await globalAxios.delete(
				`${SERVER_URL}/user/sessions/${selectedEvent.id}`
			);

			if (response.status === 200) {
				console.log("Session deleted successfully.");
				dispatch(removeSession(parseInt(selectedEvent.id)));
			}
		} catch (error) {
			console.error("Error deleting session:", error);
		} finally {
			setDeleting(false);
			setOpen(false);
		}
	};

	const eventDidMount = (info) => {
		// Change the styling from bullet point to block.
		if (info.view.type === "dayGridMonth") {
			const eventDate = info.event.startStr.split("T")[0];
			const eventsOnThisDay = document.querySelectorAll(
				`[data-date="${eventDate}"] .fc-event`
			);
			if (eventsOnThisDay.length > 3) {
				// Hide excess events
				for (let i = 3; i < eventsOnThisDay.length; i++) {
					eventsOnThisDay[i].style.display = "none";
				}
			}
		}
	};

	// Format the event data for use in the render fn.
	const convertEventsFormat = (view, eventsArray, aliases) => {
		return formatEvents(view, eventsArray, aliases, schedules);
	};

	// Styling display for individual events display on the current calendar view.
	const renderEventContent = (eventInfo) => {
		// Get the content and background colour from the contentToDisplay function
		const { content, backgroundColour } = contentToDisplay(
			eventInfo,
			currentLens,
			schedules
		);

		return (
			<div
				style={{
					// Background colour is for modified monthly view events.
					backgroundColor: backgroundColour,
					borderColor: backgroundColour,
					color: "white",
					padding: "2px 4px",
					height: "100%",
					width: "100%",
					// borderRadius: isDayGridMonth ? "3px" : "0",
					// display: isDayGridMonth ? "block" : "inline-block",
				}}
			>
				{content}
			</div>
		);
	};

	//  Used to determine current month to delete all monthly events.
	const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // Track current month
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const [isMonthlyView, setIsMonthlyView] = useState(true);

	// Update current month and view type
	const handleDatesSet = (dateInfo) => {
		const { start, view } = dateInfo;

		setCurrentMonth(start.getMonth());
		setCurrentYear(start.getFullYear());
		setIsMonthlyView(view.type === "dayGridMonth");

		dispatch(setCalendarView(view.type));
		dispatch(setCalendarDate(start.toISOString()));
	};

	const handleDeleteEvents = async () => {
		setDeletingEvents(true);

		// Format the date as YYYY-MM
		const formattedDate = `${currentYear}-${(currentMonth + 1)
			.toString()
			.padStart(2, "0")}`;

		// axios request to delete all events within the current month
		try {
			const response = await globalAxios.delete(
				`${SERVER_URL}/user/sessions`,
				{
					data: { date: formattedDate }, // Pass the formatted date as data in the request body
				}
			);
			if (response.status === 200) {
				// Dispatch the Redux action to update the state after deletion
				dispatch(deleteEventsByMonth(formattedDate));
			} else {
				console.error(
					"Failed to delete events:",
					response.data.message
				);
			}
		} catch (e) {
			console.error("Error deleting events:", e);
		} finally {
			setDeletingEvents(false);
		}
	};

	useEffect(() => {
		if (calendarRef.current && currentDate) {
			const calendarApi = calendarRef.current.getApi();
			calendarApi.gotoDate(new Date(currentDate)); // Set the date dynamically
		}
	}, [currentDate]);

	return (
		<Box
			sx={{
				"& .fc": {
					maxHeight: "90vh",
					minHeight: "90vh",
				},
				"& .fc-event": {
					cursor: "pointer",
				},
			}}
		>
			<FullCalendar
				ref={calendarRef}
				// fixedWeekCount={false}
				showNonCurrentDates={false}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					listPlugin,
					interactionPlugin,
				]}
				initialView={currentView}
				initialDate={currentDate}
				slotMinTime="08:00:00" // Start the day at 8 AM
				slotMaxTime="22:00:00"
				slotLabelInterval="05:00:00"
				headerToolbar={{
					left: "prev,next today",
					center: "title",
					right: "dayGridMonth,timeGridWeek,timeGridDay listMonth",
				}}
				firstDay={1}
				editable={true}
				expandRows={true}
				allDaySlot={false}
				events={convertEventsFormat(currentLens, events, aliases)}
				eventClick={handleEventClick}
				eventDidMount={eventDidMount} // Apply hiding logic after event is mounted
				datesSet={handleDatesSet} // Track the current month and view type
				viewDidMount={(viewInfo) => {
					if (viewInfo.view.type === "dayGridMonth") {
						document
							.querySelectorAll(".fc-event")
							.forEach((eventEl) => {
								eventEl.classList.add("fc-daygrid-event");
							});
					}
				}}
				eventContent={renderEventContent}
			/>

			<Grid container>
				{isAdmin && isMonthlyView ? (
					<Grid item xs={2}>
						<Grid
							container
							spacing={2}
							justifyContent="center"
							alignItems="center"
							sx={{ marginTop: "1rem" }}
						>
							<Grid item>
								<Button
									variant="contained"
									color="error"
									size="small"
									disableElevation
									sx={{ color: "white" }}
									onClick={handleDeleteEvents}
									disabled={deletingEvents} // Button is disabled when loading is true
								>
									{deletingEvents ? (
										<CircularProgress
											size={24}
											sx={{ color: "white" }}
										/>
									) : (
										"Delete Events"
									)}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				) : (
					""
				)}
			</Grid>

			<CalendarDialog
				open={open}
				selectedEvent={selectedEvent}
				schedules={schedules}
				deleting={deleting}
				handleDelete={handleDelete}
				handleClose={handleClose}
			/>
		</Box>
	);
};

export default Calendar;
