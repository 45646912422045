import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Define a type for the slice state
interface MiniNavState {
	activeTab: number;
	dialogOpen: boolean;
}

// Define the initial state using that type
const initialState: MiniNavState = {
	activeTab: 0,
	dialogOpen: false,
};

export const miniNavSlice = createSlice({
	name: 'miniNav',
	initialState,
	reducers: {
		setActiveTab: (state, action: PayloadAction<number>) => {
			state.activeTab = action.payload;
		},
		openDialog: (state) => {
			state.dialogOpen = true;
		},
		closeDialog: (state) => {
			state.dialogOpen = false;
		},
	},
});

export const { setActiveTab, openDialog, closeDialog } = miniNavSlice.actions;

// Selectors
export const selectActiveTab = (state: RootState) => state.miniNav.activeTab;
export const selectDialogOpen = (state: RootState) => state.miniNav.dialogOpen;

export default miniNavSlice.reducer;
