import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

const fieldGroups = {
	patientDetails: ['First Name', 'Last Name', 'Title', 'Gender', 'DOB', 'Address'],
	patientContacts: ['Home', 'Work', 'Mobile', 'Email', 'Next of Kin'],
	billingDetails: ['Medicare', 'Health Fund', 'Health Fund Number', 'Account Type'],
	sessionDetails: ['Date of Service', 'Hospital', 'Surgeon', 'Assistant', 'Anaesthetist', 'Procedure'],
};

// Define a type for the slice state
export interface FieldsState {
	fields: string[];
	showItemNumbers: boolean;
	lastItemNumber: string,
	selectedPreset: string;
	customPresetName: string;
	isDirty: boolean;
	showOverwriteCheckBox: boolean;
	overwritePreset: boolean;
}

// Define the initial state using that type
const initialState: FieldsState = {
	fields: [
		'First Name', 'Last Name', 'Title', 'Gender', 'DOB', 'Address',
		'Home', 'Work', 'Mobile', 'Email', 'Next of Kin',
		'Medicare', 'Health Fund', 'Health Fund Number', 'Account Type',
		'Date of Service', 'Hospital', 'Surgeon', 'Assistant', 'Anaesthetist', 'Procedure',
		"Item Numbers: 10",
	],
	showItemNumbers: true,
	lastItemNumber: '10', // track Item Number for when user toggles showItemNumbers.
	selectedPreset: 'none',
	customPresetName: '',
	isDirty: false,
	showOverwriteCheckBox: false,
	overwritePreset: false,
}

export const fieldsSlice = createSlice({
	name: 'fields',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		setAllFields: (state, action: PayloadAction<string[]>) => {
			state.fields = action.payload;
		},
		setSelectedPreset: (state, action: PayloadAction<string>) => {
			state.selectedPreset = action.payload;
		},
		setCustomPresetName: (state, action: PayloadAction<string>) => {
			state.customPresetName = action.payload;
		},
		setIsDirty: (state, action: PayloadAction<boolean>) => {
			state.isDirty = action.payload;
		},
		setShowOverwriteCheckBox: (state, action: PayloadAction<boolean>) => {
			state.showOverwriteCheckBox = action.payload;
		},
		setOverwritePreset: (state, action: PayloadAction<boolean>) => {
			state.overwritePreset = action.payload;
		},
		addSelection: (state, action: PayloadAction<string>) => {
			state.fields = [...state.fields, action.payload]
		},
		removeSelection: (state, action: PayloadAction<string>) => {
			state.fields = state.fields.filter(field => field !== action.payload)
		},
		changeItemNumber: (state, action: PayloadAction<string>) => {
			const itemNumberIndex = state.fields.findIndex(field => field.startsWith("Item Numbers:"));

			state.lastItemNumber = action.payload;

			if (itemNumberIndex !== -1) {
				state.fields[itemNumberIndex] = `Item Numbers: ${action.payload}`;
			} else if (state.showItemNumbers) {
				state.fields.push(`Item Numbers: ${action.payload}`);
			}
		},
		reorderSelection: (state, action: PayloadAction<string[]>) => {
			state.fields = action.payload
		},
		toggleGroupSelection: (state, action: PayloadAction<string>) => {
			const groupKey = action.payload;
			const allFieldsInGroup = fieldGroups[groupKey]; // Fields for the specified group

			// Check if all fields in this group are selected
			const allSelected = allFieldsInGroup.every(field => state.fields.includes(field));

			if (allSelected) {
				// Remove all fields in this group from the state
				state.fields = state.fields.filter(field => !allFieldsInGroup.includes(field));
			} else {
				// Add missing fields from this group to the state, avoiding duplicates
				const updatedFields = new Set([...state.fields, ...allFieldsInGroup]);
				state.fields = Array.from(updatedFields);
			}
		},
		toggleItemNumbersVisibility: (state) => {
			state.showItemNumbers = !state.showItemNumbers;
			if (state.showItemNumbers) {
				// Use the last known item number to restore the field
				state.fields.push(`Item Numbers: ${state.lastItemNumber}`);
			} else {
				// Before removing, update lastItemNumber by extracting the number from the field
				const itemNumberField = state.fields.find(field => field.startsWith("Item Numbers:"));
				if (itemNumberField) {
					state.lastItemNumber = itemNumberField.split(': ')[1];
				}
				// Remove the item number field
				state.fields = state.fields.filter(field => !field.startsWith("Item Numbers:"));
			}
		},
	}
});

export const {
	setAllFields,
	setSelectedPreset,
	setCustomPresetName,
	setIsDirty,
	setShowOverwriteCheckBox,
	setOverwritePreset,
	addSelection,
	removeSelection,
	changeItemNumber,
	reorderSelection,
	toggleGroupSelection,
	toggleItemNumbersVisibility,
} = fieldsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectFieldsState = (state: RootState) => state.fields.fields;
export const selectCustomPresetName = (state: RootState) => state.fields.customPresetName;

export default fieldsSlice.reducer