import React, { useState } from "react";

import { Typography, Button, Accordion, AccordionDetails, AccordionSummary, Grid, Box, Paper } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../../store/hooks.ts";
import { closeDialog } from '../../../store/slices/miniNavSlice.ts';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const OverlayContent = () => {

	const filesStatus = useAppSelector(state => state.uploadsResponseModal.filesStatus);
	const dispatch = useAppDispatch();

	// State to track expanded panels as an array
	const [expandedPanels, setExpandedPanels] = useState([]);

	const handleAccordionChange = (panel) => (event, isExpanded) => {
		if (isExpanded) {
			// Add panel to array if expanded
			setExpandedPanels(prev => [...prev, panel]);
		} else {
			// Remove panel from array if not expanded
			setExpandedPanels(prev => prev.filter(item => item !== panel));
		}
	};

	const handleClose = () => {
		dispatch(closeDialog());
	};


	return (
		<>
			<Grid item xs={12}>
				<div style={{ width: '100%' }}>
					<Grid>
						<Box display="flex" justifyContent="flex-end" alignItems="center">
							<Button onClick={handleClose} sx={{ mx: 1 }}>
								Close
							</Button>
						</Box>
					</Grid>
					{filesStatus.map(({ file, status, message }, index) => (
						<Accordion
							key={index}
							elevation={0}
							square
							expanded={expandedPanels.includes(`panel${index}`)}
							onChange={handleAccordionChange(`panel${index}`)}
							sx={(theme) => ({
								// Your existing styling logic here
								border: `1px solid ${theme.palette.divider}`,
								borderBottom: 'none',
								'&:before': {
									display: 'none',
								},
								...(expandedPanels.includes(`panel${index}`) && {
									borderBottom: `1px solid ${theme.palette.divider}`,
								}),
								...(expandedPanels.includes(`panel${index + 1}`) && {
									borderBottom: `1px solid ${theme.palette.divider}`,
								}),
								'&:last-of-type': {
									borderBottom: `1px solid ${theme.palette.divider}`,
								},
							})}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={`panel${index}bh-content`}
								id={`panel${index}bh-header`}
							>
								<Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
									{status === 'success' ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
									{file}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography color={status === 'success' ? 'green' : 'red'}>
									Status: {status}
									{message && ` - Message: ${message}`}
								</Typography>
							</AccordionDetails>
						</Accordion>
					))}
				</div>
				<Grid item xs={12}>
					<Paper variant="outlined" sx={{ mb: 1, p: 2 }}>
						<Box display="flex" justifyContent="space-between" mt={2}>
							<Typography>Total Files: {filesStatus.length}</Typography>
							<Typography color="success.main">Success: {filesStatus.filter(file => file.status === 'success').length}</Typography>
							<Typography color="error.main">Fails: {filesStatus.filter(file => file.status !== 'success').length}</Typography>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}

export default OverlayContent;