import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state

type SeverityType = 'error' | 'warning' | 'info' | 'success';
export interface SnackbarState {
	open: boolean;
	severity: SeverityType;
	text: string;
}

// Define the initial state using that type
const initialState: SnackbarState = {
	open: false,
	severity: "success",
	text: "",
}

export const snackbarSlice = createSlice({
	name: 'snackbar',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		setSnackbarOpen: (state, action: PayloadAction<boolean>) => {
			state.open = action.payload
		},
		setSnackbarSeverity: (state, action: PayloadAction<SeverityType>) => {
			state.severity = action.payload
		},
		setSnackbarText: (state, action: PayloadAction<string>) => {
			state.text = action.payload
		},
	}
})

export const { setSnackbarOpen, setSnackbarSeverity, setSnackbarText } = snackbarSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectSnackbarOpen = (state: RootState) => state.snackbar.open;
export const selectSnackbarSeverity = (state: RootState) => state.snackbar.severity;
export const selectSnackbarText = (state: RootState) => state.snackbar.text;

export default snackbarSlice.reducer