import React, { useState } from "react";
import { SERVER_URL } from "../../config.js";
import { useAppSelector, useAppDispatch } from '../../store/hooks.ts';
import { updateState } from '../../store/slices/bugReportModalSlice.ts';
import { setSnackbarOpen, setSnackbarSeverity, setSnackbarText } from '../../store/slices/snackbarSlice.ts';
import { toPng } from 'html-to-image';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogProps,
	Button,
	TextField,
	IconButton,
	Grid,
	Box,
	Typography,
	Badge,
} from "@mui/material";

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description'; // Generic icon for document files like Word
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';

import "./BugReportModal.css";

const defaultSrc = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=";

const BugReportModal = () => {

	const open = useAppSelector(state => state.bugReportModal.active);
	const dispatch = useAppDispatch();

	const [subject, setSubject] = useState("");
	const [name, setName] = useState("");
	const [text, setText] = useState("");

	const [uploadedFile, setUploadedFile] = useState(null);

	const [takingSS, setTakingSS] = useState(false);

	const handleClose: DialogProps["onClose"] = (_e, reason) => {
		if (reason && reason === "backdropClick")
			return;
		dispatch(updateState(false));
	};

	const handleTextChange = (e) => {
		setText(e.target.value);
	};

	const handleNameChange = (e) => {
		setName(e.target.value);
	};

	const handleSubjectChange = (e) => {
		setSubject(e.target.value);
	};

	const handleUploadFile = (e) => {
		const fileInput = document.getElementById('file-upload-input');
		fileInput.click();
	};

	// This function will be called when the user selects a file
	const handleFileChange = (event) => {
		// Ensure there's at least one file selected
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];

			// Continue your logic, for example, setting state
			setUploadedFile(file);
		};
	};

	const handleRemoveFile = () => {
		setUploadedFile(null); // Update your state to reflect that the file has been removed

		// Reset the file input
		const fileInput = document.getElementById('file-upload-input');
		if (fileInput) {
			fileInput.value = "";
		}
	};


	const node = document.querySelector('body');
	const filter = (node: HTMLElement) => {
		const exclusionIds = ['bug-report-dialog']; // Example IDs to exclude
		return !exclusionIds.includes(node.id);
	}
	const handleSS = () => {
		if (node) {
			toPng(node, { filter: filter })
				.then((dataUrl) => {
					const img = new Image();
					img.src = dataUrl;
					document.querySelector("#bug-report-screenshot").src = dataUrl;
					setTakingSS(false);
					setViewSrc(dataUrl);
				})
		}
	};

	const handleRemoveScreenshot = () => {
		// Reset the screenshot to the default state
		setViewSrc(defaultSrc);
		// You may also want to do additional state cleanup here
	};

	const [viewImg, setViewImg] = useState(false);
	const [viewSrc, setViewSrc] = useState(defaultSrc)

	const handleViewImage = () => {
		setViewImg(true);
	};

	const handleSubmitEmail = (e) => {
		e.preventDefault();
		// You will handle the file upload and email sending here
		console.log("Sending email to:");
		// Use FormData to prepare data for sending
		const formData = new FormData();

		formData.append('subject', subject);
		formData.append("name", name);
		formData.append("text", text);

		// Append the screenshot only if it has been updated/taken by the user
		if (viewSrc !== defaultSrc) {
			formData.append("screenshot", viewSrc);
		};

		// Append the uploaded file if it exists
		if (uploadedFile) {
			formData.append('attachment', uploadedFile);
		};


		// Send the formData to your server/API
		fetch(SERVER_URL + '/send-email', {
			method: 'POST',
			body: formData,
		}).then((res) => {
			if (res.status === 200) {
				dispatch(setSnackbarOpen(true));
				dispatch(setSnackbarSeverity("success"));
				dispatch(setSnackbarText("Bug report sent successfully!"));
				dispatch(updateState(false));
			}
		});
	};

	return (
		<Dialog
			id="bug-report-dialog"
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "50%",
						minWidth: "300px",
					},
				},
			}}
		>
			<DialogTitle id="alert-dialog-title">
				{"Report a problem?"}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} sx={{ width: '100%' }}> {/* Ensure the container takes full width */}
					<Grid item xs={12} sm={4} sx={{ flexGrow: 1, flexBasis: '30%' }}> {/* Adjust for Name */}
						<TextField
							label="Name"
							variant="outlined"
							fullWidth
							value={name} // Assuming you have a state variable for this
							onChange={handleNameChange} // And a handler function
							sx={{ marginTop: "10px", marginBottom: "20px" }}
						/>
					</Grid>
					<Grid item xs={12} sm={8} sx={{ flexGrow: 3, flexBasis: '70%' }}> {/* Adjust for Subject */}
						<TextField
							label="Subject"
							variant="outlined"
							fullWidth
							value={subject}
							onChange={handleSubjectChange}
							sx={{ marginTop: "10px", marginBottom: "20px" }}
						/>
					</Grid>
				</Grid>
				<TextField
					label="Report"
					variant="outlined"
					fullWidth
					multiline
					rows={10}
					value={text}
					onChange={handleTextChange}
					sx={{ marginTop: "10px" }}
				/>
			</DialogContent>
			<DialogActions
				sx={{ marginBottom: "20px" }}
			>
				<ImgModal
					open={viewImg}
					setOpen={setViewImg}
					src={viewSrc}
				/>
				<Grid container spacing={2} alignItems="flex-end">
					{/* Column for the bug report screenshot */}
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{/* In your DialogActions or wherever the image is displayed: */}
						{viewSrc !== defaultSrc && (
							<Badge
								badgeContent={
									<IconButton
										aria-label="remove screenshot"
										size="small"
										onClick={handleRemoveScreenshot} // This function will be defined to remove the screenshot
										sx={{
											backgroundColor: 'primary.main', // Use your theme color
											color: 'white',
											"&:hover": {
												backgroundColor: 'primary.dark', // Darken color on hover
											},
										}}
									>
										<DeleteIcon fontSize="inherit" />
									</IconButton>
								}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
							>
								<img
									id="bug-report-screenshot"
									src={viewSrc}
									alt="bug-report-screenshot"
									onClick={handleViewImage}
									style={{ maxWidth: '100%', maxHeight: "100%" }} // Adjust as needed
								/>
							</Badge>
						)}

						{/* If the viewSrc is the default, just show the image without the display non badge (to prevent size change) */}
						{viewSrc === defaultSrc && (
							<Badge
								badgeContent={
									<IconButton
										aria-label="remove screenshot"
										size="small"
										sx={{
											display: "none",
										}}
									>
										<DeleteIcon fontSize="inherit" />
									</IconButton>
								}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
							>
								<img
									id="bug-report-screenshot"
									src={viewSrc}
									alt="bug-report-screenshot"
									onClick={handleViewImage}
									style={{ maxWidth: '100%', maxHeight: "100%" }}
								/>
							</Badge>
						)}
					</Grid>

					{/* Column for the buttons */}
					<Grid item xs={6}>
						<Box display="flex" flexDirection="column" alignItems="flex-end" width="100%" sx={{ paddingRight: "10px" }}>
							{/* Uploaded file name and icon */}
							<Box display="flex" justifyContent="center" alignItems="center" width="100%" sx={{ flexGrow: 1, marginBottom: '20px' }}>
								{uploadedFile ? (
									<>
										{getFileIcon(uploadedFile)}
										<Typography
											variant="subtitle1"
											sx={{
												marginLeft: '8px',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												maxWidth: '80%' // Adjust this value based on your layout needs
											}}>
											{uploadedFile.name}
										</Typography>
										<IconButton
											onClick={handleRemoveFile}
											aria-label="delete"
											size="large"
											sx={{
												padding: 0,
												paddingLeft: "20px",
												minHeight: 0,
												minWidth: 0,
												"&:hover": { backgroundColor: "transparent" },
											}}
										>
											<DeleteIcon />
										</IconButton>
									</>
								) : (
									'No file uploaded'
								)}
							</Box>

							{/* Upload button */}
							<Button
								onClick={handleUploadFile} // Define this function to handle upload
								variant="outlined"
								sx={{ width: '100%', marginBottom: '20px' }} // Full width and margin at the bottom
							>
								Upload
							</Button>
							{/* Hidden file input */}
							<input
								type="file"
								id="file-upload-input"
								style={{ display: 'none' }}
								onChange={handleFileChange}
							/>
							{/* Camera, Cancel, and Submit buttons */}
							<Box display="flex" justifyContent="flex-end" alignItems="center">
								<IconButton
									id="screenshot-button"
									aria-label="screenshot-button"
									onClick={() => {
										setTakingSS(true);
										handleSS();
									}}
									disabled={takingSS}
									disableRipple
								>
									<CameraAltIcon />
								</IconButton>
								<Button onClick={handleClose} sx={{ mx: 1 }}>
									Cancel
								</Button>
								<Button
									variant="contained"
									onClick={handleSubmitEmail}
									disableElevation
									autoFocus
									sx={{ color: "white" }}
								>
									Submit
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog >
	)
};

export default BugReportModal;


const ImgModal = ({ open, setOpen, src }) => {

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			fullScreen
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "80%",
						height: "auto",
					},
				},
			}}
		>
			<img src={src} alt="" />
		</Dialog>
	);
};

const getFileIcon = (file) => {
	if (!file) return <DescriptionIcon />; // Default for unknown file types

	const fileType = file.type.split('/')[0];
	const fileExtension = file.name.split('.').pop().toLowerCase();

	if (fileType === 'image') {
		return <ImageIcon />;
	} else if (fileType === 'application' && fileExtension === 'pdf') {
		return <PictureAsPdfIcon />;
	} else if (fileType === 'application' && (fileExtension === 'doc' || fileExtension === 'docx')) {
		return <DescriptionIcon />;
	}

	// If none of the specific types match, return a generic file icon
	return <InsertDriveFileIcon />;
};