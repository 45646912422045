import * as React from 'react';
import { useAppSelector, useAppDispatch } from '../../store/hooks.ts';
import { updateState } from '../../store/slices/bugReportModalSlice.ts';
import {
	NavLink, Outlet
} from "react-router-dom";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

import DragDrop from '../dragdrop/DragDrop.tsx';
import MiniNav from '../mininav/MiniNav.tsx';
import BugReportModal from '../bugreportmodal/BugReportModal.tsx';
// import UploadsResponseModal from '../uploadresponsemodal/UploadResponseModal.tsx';

import logoWithBrandName from "../../SwiftMed Horizontal WHITE.png";

import "./SideBar.css";


const SideBar = () => {

	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

	// Dynamic drawerWidth based on screen size
	const drawerWidth = isSmall ? 240 : 400;

	// Dynamic Toolbar height based on screen size
	const toolbarHeight = isSmall ? '48px' : '68px'; // You can adjust these values
	const navLinkHeight = isSmall ? '48px' : '68px';
	const navLinkYPadding = isSmall ? '7px' : '14px';

	const logoSize = isSmall ? '32px' : '42px';



	const open = useAppSelector(state => state.bugReportModal.active);
	const isAdmin = useAppSelector(state => state.user.admin);
	const dispatch = useAppDispatch();

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					width: `100%`,
					boxShadow: "none",
					'.MuiToolbar-root': { maxHeight: toolbarHeight, minHeight: "0px" } // Apply the dynamic height
				}}
			>
				<Toolbar>
					<Box sx={{ width: `calc(${drawerWidth}px - 24px)`, display: "flex", alignItems: "center" }}>
						<Box sx={{ marginRight: "2rem", display: "flex" }}>
							<img src={logoWithBrandName} height={logoSize} width={"auto"} alt="logo" />
						</Box>
					</Box>

					<MenuItem key={1} disableRipple className="menu-nav" sx={{ margin: "auto", maxHeight: navLinkHeight, minHeight: "0px" }}>
						<NavLink to="/template"
							className={({ isActive }) =>
								isActive ? "active" : ""
							}
							style={{
								textDecoration: "none",
								maxHeight: navLinkHeight,
								minHeight: "0px",
								paddingTop: navLinkYPadding,
								paddingBottom: navLinkYPadding
							}}
							end
						>
							<IconButton className="menu-nav-icon" disableRipple aria-label="">
								<Badge color="primary">
									<AppRegistrationIcon />
								</Badge>
							</IconButton>
							Template
						</NavLink>
					</MenuItem>
					<MenuItem key={2} disableRipple className="menu-nav" sx={{ margin: "auto", maxHeight: navLinkHeight, minHeight: "0px" }}>
						<NavLink to="/calendar" className={({ isActive }) =>
							isActive ? "active" : ""
						}
							style={{
								textDecoration: "none",
								maxHeight: navLinkHeight,
								minHeight: "0px",
								paddingTop: navLinkYPadding,
								paddingBottom: navLinkYPadding
							}}
							end
						>
							<IconButton className="menu-nav-icon" disableRipple aria-label="">
								<Badge color="primary">
									<CalendarMonthIcon />
								</Badge>
							</IconButton>
							Calendar
						</NavLink>
					</MenuItem>
					<MenuItem key={3} disableRipple className="menu-nav" sx={{ margin: "auto", maxHeight: navLinkHeight, minHeight: "0px" }}>
						<NavLink to="/settings" className={({ isActive }) =>
							isActive ? "active" : ""
						}
							style={{
								textDecoration: "none",
								maxHeight: navLinkHeight,
								minHeight: "0px",
								paddingTop: navLinkYPadding,
								paddingBottom: navLinkYPadding
							}}
							end
						>
							<IconButton className="menu-nav-icon" disableRipple aria-label="">
								<Badge color="primary">
									<SettingsIcon />
								</Badge>
							</IconButton>
							Settings
						</NavLink>
					</MenuItem>
					<MenuItem key={4} disableRipple className="menu-nav" sx={{ margin: "auto", maxHeight: navLinkHeight, minHeight: "0px" }}>
						<NavLink to="/profile" className={({ isActive }) =>
							isActive ? "active" : ""
						}
							style={{
								textDecoration: "none",
								maxHeight: navLinkHeight,
								minHeight: "0px",
								paddingTop: navLinkYPadding,
								paddingBottom: navLinkYPadding
							}}
							end
						>
							<IconButton className="menu-nav-icon" disableRipple aria-label="">
								<Badge color="primary">
									<AccountCircleIcon />
								</Badge>
							</IconButton>
							Profile
						</NavLink>
					</MenuItem>
					{isAdmin ?
						<MenuItem key={5} disableRipple className="menu-nav" sx={{ margin: "auto", maxHeight: navLinkHeight, minHeight: "0px" }}>
							<NavLink to="/admin" className={({ isActive }) =>
								isActive ? "active" : ""
							}
								style={{
									textDecoration: "none",
									maxHeight: navLinkHeight,
									minHeight: "0px",
									paddingTop: navLinkYPadding,
									paddingBottom: navLinkYPadding
								}}
								end
							>
								<IconButton className="menu-nav-icon" disableRipple aria-label="">
									<Badge color="primary">
										<AdminPanelSettingsIcon />
									</Badge>
								</IconButton>
								Admin
							</NavLink>
						</MenuItem>
						: null
					}

					<IconButton
						id="bug-report-icon"
						aria-label="create bug report"
						onClick={() => dispatch(updateState(open ? false : true))}
						disableRipple
					>
						<ReportProblemRoundedIcon sx={{ color: "#1a262a" }} />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
						zIndex: 1,
						backgroundColor: "#F2F8FA",
						marginLeft: "9px",
						height: "calc(100% - 10px)",
						// borderRight: "10px solid #78c4dc",
					},
				}}
				variant="permanent"
				anchor="left"
			>
				<MiniNav style={{ marginTop: toolbarHeight, maxHeight: `calc(55% - ${toolbarHeight})` }} />
				<DragDrop size={{ height: "45%" }} />
			</Drawer>
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
			>
				<Box marginTop={`calc(${toolbarHeight} - 10px)`}>
					<Outlet />
				</Box>
				{/* List of Modals.*/}
				{/* <UploadsResponseModal /> */}
				<BugReportModal />
			</Box>
		</Box >
	);
};

export default SideBar;