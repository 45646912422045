// components/LensView.tsx

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../../store/hooks.ts"; // Assuming you have this set up
import { setCalendarLens } from "../../../store/slices/calendarSlice.ts";

const LensView = () => {
	const dispatch = useAppDispatch(); // Initialize dispatch
	const currentLens = useAppSelector((state) => state.calendar.lens);

	const handleLensChange = (lens: string) => {
		dispatch(setCalendarLens(lens)); // Dispatch the action with the lens type
	};

	// Define a function to check if the button is selected
	const isSelected = (lens: string) => currentLens === lens;

	// List of buttons with lens types and labels
	const lensButtons = [
		{ lens: "location", label: "Location" },
		{ lens: "uploadStatus", label: "Upload Status" },
		{ lens: "feeEstimate", label: "Fee Estimate" },
		{ lens: "fundingStatus", label: "Funding Status" },
	];

	return (
		<Box>
			<Typography
				variant="body1"
				gutterBottom
				fontWeight={600}
				textAlign="center"
			>
				Lens
			</Typography>
			{/* Dynamically create buttons */}
			{lensButtons.map((button) => (
				<Button
					key={button.lens} // Unique key for each button
					disableElevation
					disableRipple
					variant="contained"
					color="primary"
					size="small"
					fullWidth
					onClick={() => handleLensChange(button.lens)} // Dispatch the lens change
					sx={{
						marginBottom: "0.5rem",
						backgroundColor: `${
							isSelected(button.lens) ? "#34859E" : "primary"
						}`, // Conditionally style button
					}}
				>
					{button.label}
				</Button>
			))}
		</Box>
	);
};

export default LensView;
