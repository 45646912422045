// components/LensView.tsx

import React from "react";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useAppSelector } from "../../../store/hooks.ts"; // Assuming you have this set up

const Legend = () => {
	const currentLens = useAppSelector((state) => state.calendar.lens);

	// Define the colour legend based on the current view
	const getLegendForView = () => {
		switch (currentLens) {
			case "location":
				return [
					{ label: "Morning", colour: "#1976d2" },
					{ label: "Afternoon", colour: "#f57c00" },
					{ label: "Twilight", colour: "#388e3c" },
					{ label: "Unknown", colour: "grey" },
				];
			case "uploadStatus":
				return [
					{ label: "Upload Complete", colour: "#388e3c" },
					{ label: "Upload Pending", colour: "#f57c00" },
				];
			case "feeEstimate":
				return [
					{ label: "Estimate", colour: "#388e3c" },
					{ label: "No Estimate", colour: "grey" },
				];
			case "fundingStatus":
				return [
					{ label: "Funding Available", colour: "#388e3c" },
					{ label: "Funding Missing", colour: "#fbc02d" },
				];
			default:
				return [];
		}
	};

	const legend = getLegendForView();

	return (
		<Box>
			<Typography
				variant="body1"
				gutterBottom
				fontWeight={600}
				textAlign="center"
			>
				Legend
			</Typography>
			<List>
				{legend.map((item, index) => (
					<ListItem
						key={index}
						sx={{ display: "flex", alignItems: "center" }}
					>
						{/* Colour Box */}
						<Box
							sx={{
								width: 20,
								height: 20,
								backgroundColor: item.colour,
								marginRight: 2,
								borderRadius: "50%", // Optional for rounded circles
							}}
						/>
						<ListItemText primary={item.label} />
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default Legend;
