import React, { useState, useEffect } from "react";
import globalAxios from "../../globalAxios.js";

import { SERVER_URL } from "../../config.js";

import { useAppSelector, useAppDispatch } from '../../store/hooks.ts';
import { setUser } from '../../store/slices/userSlice.ts';
import { setAuth, setToken } from '../../store/slices/authenticateSlice.ts';
import { setAllFields, setSelectedPreset } from '../../store/slices/fieldsSlice.ts';

import { fetchUserSessions } from "../../index.js";

import { Link, useNavigate } from 'react-router-dom';

import { Box, Container, Typography, TextField, Button, Checkbox, FormControlLabel } from "@mui/material";
import { LoadingButton } from '@mui/lab';

import logo from "../../SwiftMed Logo.png";
import brandName from "../../SwiftMed Name.png";

import "./Login.css";


const Login = ({ page }) => {

	const [errorDisplay, setErrorDisplay] = useState(false);
	const [errorMessage, setErrorMessage] = useState("Username or password is incorrect");

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [keepSignedIn, setKeepSignedIn] = useState(false);

	const [isLoggingIn, setIsLoggingIn] = useState(false);

	const isAuthenticated = useAppSelector((state) => state.authenticate.isAuthenticated);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();


	const handleErrorMessage = (err) => {
		setErrorMessage(err);
		setErrorDisplay(true)
	};

	// Function to update the email state
	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	// Function to update the password state
	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const handleCheckboxChange = (e) => {
		setKeepSignedIn(e.target.checked);
		localStorage.setItem('keepSignedInChecked', e.target.checked ? 'true' : 'false');
	};

	useEffect(() => {
		const keepSignedInChecked = localStorage.getItem('keepSignedInChecked') === 'true';
		setKeepSignedIn(keepSignedInChecked);
	}, []);

	const handleLogin = (e) => {
		e.preventDefault();

		// Validation: Check if email or password fields are empty
		if (!email.trim() || !password.trim()) {
			handleErrorMessage('Email and password are required.')
			return; // Stop the function if validation fails
		}

		setIsLoggingIn(true);

		globalAxios.post(SERVER_URL + "/user/authenticate", {
			email,
			password,
			keepSignedIn,
		})
			.then(response => {
				// If the request was successful
				if (response.status === 200) {

					const { token, user } = response.data;
					console.log("user: ", user)
					dispatch(setUser(user));
					dispatch(setToken(token));

					// Check if defaultFields exists before dispatching
					if (user.settings && user.settings.defaultPreset) {
						dispatch(setSelectedPreset(user.settings.defaultPreset.name));
						dispatch(setAllFields(user.settings.defaultPreset.fields));
					} else {
						console.log("No default fields to set.");
					}

					// Fetch and set user's sessions
					fetchUserSessions(dispatch);

					dispatch(setAuth(true)); // Dispatch the action to update your auth state

					navigate('/'); // Redirect to home page after successful login
				} else {
					// Handle failed login
					console.log(response.data.message || 'Failed to login'); // Log error message from response
					dispatch(setAuth(false)); // Ensure auth state is updated to not authenticated
				}
			})
			.catch(error => {
				console.error('Login error:', error);
				handleErrorMessage(error.response.data.message)
				console.log(error.response ? error.response.data.message : 'Server error or no response'); // Log error message based on the response
			})
			.finally(() => {
				setIsLoggingIn(false); // End the login attempt, regardless of outcome
			});
	};

	useEffect(() => {
		if (isAuthenticated) {
			navigate('/'); // Redirect to the path you want the user to go after login
		};
	}, [isAuthenticated, navigate]);


	const getContent = () => {
		switch (page) {
			case 'signup':
				return (
					<>
						<img style={{ verticalAlign: 'text-top', maxHeight: "1.8rem", marginBottom: "1rem" }} src={brandName} alt="Brand Name" />
						<Typography
							fontWeight={600}
							component="h1"
							variant="body1"
							sx={{ mb: 1 }}
						>
							Create Account
						</Typography>
						{/* Error message */}
						<Typography
							component="p"
							variant="body2"
							sx={{
								visibility: false ? 'visible' : 'hidden', // Toggle visibility
								color: 'error.main',
								height: '20px', // Reserve space for the message
								textAlign: 'center',
								mb: 1,
							}}
						>
							Username already exists / Password doesn't match
						</Typography>
						<TextField
							size="small"
							variant="outlined"
							margin="normal"
							required
							id="email"
							label="Email"
							name="email"
							autoComplete="email"
							autoFocus
							sx={{ mb: 2, width: "80%" }}
						/>
						<TextField
							size="small"
							variant="outlined"
							margin="normal"
							required
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							sx={{ mb: 1, width: "80%" }}
						/>
						<TextField
							size="small"
							variant="outlined"
							margin="normal"
							required
							name="confirm-password"
							label="Confirm Password"
							type="confirm-password"
							id="confirm-password"
							autoComplete="current-password"
							sx={{ mb: 3, width: "80%" }}
						/>
						<Button
							type="submit"
							variant="contained"
							disabled
							disableElevation
							sx={{ mb: 4, width: "80%", bgcolor: 'primary.main', color: 'common.white' }}
							onClick={handleLogin}
						>
							Create Account
						</Button>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
							<Typography
								variant="body2"
							>
								Have an existing account
								<Link
									to="/login"
									className="login-link-style"
									style={{ textDecoration: "none" }}
								>
									&nbsp;Sign In
								</Link>
							</Typography>
						</Box>
					</>
				);
			case 'resetPassword':
				return (
					<>
						<img style={{ verticalAlign: 'text-top', maxHeight: "1.8rem", marginBottom: "1rem" }} src={brandName} alt="Brand Name" />
						<Typography
							fontWeight={600}
							component="h1"
							variant="body1"
							sx={{ mb: 1 }}
						>
							Reset Password
						</Typography>
						<Typography
							component="p"
							variant="body2"
							sx={{
								height: '20px', // Reserve space for the message
								textAlign: 'center',
								mb: 1,
							}}
						>
							Enter email to reset password
						</Typography>
						<TextField
							size="small"
							variant="outlined"
							margin="normal"
							required
							id="email"
							label="Email"
							name="email"
							autoComplete="email"
							autoFocus
							sx={{ mb: 2, width: "80%" }}
						/>
						<Button
							type="submit"
							variant="contained"
							disableElevation
							sx={{ mb: 4, width: "80%", bgcolor: 'primary.main', color: 'common.white' }}
							onClick={handleLogin}
						>
							Reset password
						</Button>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
							<Typography
								variant="body2"
							>
								Return to
								<Link
									to="/login"
									className="login-link-style"
									style={{ textDecoration: "none" }}
								>
									&nbsp;Sign In
								</Link>
							</Typography>
						</Box>
					</>
				);
			case 'signin':
			default:
				return (
					<>
						<img style={{ verticalAlign: 'text-top', maxHeight: "1.8rem", marginBottom: "1rem" }} src={brandName} alt="Brand Name" />
						<Typography
							fontWeight={600}
							component="h1"
							variant="body1"
							sx={{ mb: 1 }}
						>
							Sign In
						</Typography>
						{/* Error message */}
						<Typography
							component="p"
							variant="body2"
							sx={{
								visibility: errorDisplay ? 'visible' : 'hidden', // Toggle visibility
								color: 'error.main',
								height: '20px', // Reserve space for the message
								textAlign: 'center',
								mb: 1,
							}}
						>
							{errorMessage}
						</Typography>
						<form style={{ display: "contents" }} onSubmit={handleLogin}>
							<TextField
								value={email}
								size="small"
								variant="outlined"
								margin="normal"
								required
								id="email"
								label="Email"
								name="email"
								autoComplete="email"
								autoFocus
								sx={{ mb: 2, width: "80%" }}
								onChange={handleEmailChange}
							/>
							<TextField
								value={password}
								size="small"
								variant="outlined"
								margin="normal"
								required
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								sx={{ mb: 1, width: "80%" }}
								onChange={handlePasswordChange}
							/>
							<FormControlLabel
								control={<Checkbox checked={keepSignedIn} color="primary" onChange={handleCheckboxChange} />}
								label="Keep me signed in on this device"
								sx={{ mb: 2 }}
							/>
							{isLoggingIn ? (
								<LoadingButton
									type="submit"
									loading
									variant="contained"
									disableElevation
									sx={{ mb: 2, width: "80%", bgcolor: 'primary.main', color: 'common.white' }}
								>
									Loading...
								</LoadingButton>
							) : (
								<Button
									type="submit"
									variant="contained"
									disableElevation
									sx={{ mb: 2, width: "80%", bgcolor: 'primary.main', color: 'common.white' }}
								// onClick={handleLogin}
								>
									Sign In
								</Button>
							)}
						</form>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
							<Typography
								variant="body2"
								sx={{ paddingBottom: "10px" }}
							>
								<Link
									to="/reset-password"
									className="login-link-style"
									style={{ textDecoration: "none" }}
								>
									Forgot password?
								</Link>
							</Typography>
							<Typography
								variant="body2"
							>
								No account?
								<Link
									to="/signup"
									className="login-link-style"
									style={{ textDecoration: "none" }}
								>
									&nbsp;Create one
								</Link>
							</Typography>
						</Box>
					</>
				);
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '100vh',
				backgroundColor: '#f0f2f5',
				p: 4, // padding
			}}
		>
			<Container component="main" maxWidth="xs" sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				bgcolor: 'background.paper',
				p: 4,
				borderRadius: '10px',
				position: 'relative', // Needed for absolute positioning of the logo
				width: '100%', // Ensures the container fills the width of the parent Box
				boxSizing: 'border-box', // Ensures padding is included in width
				paddingTop: "120px",
				paddingBottom: "150px",
				minHeight: "670px",
			}}>
				<Box
					component="img"
					src={logo}
					alt="Swift-Med Logo"
					sx={{
						position: 'absolute',
						top: 20, // Adjust these values as needed
						left: 20,
						width: 40, // Set the size of the logo
						height: 40, // Set the size of the logo
					}}
				/>
				{getContent()}
			</Container>
		</Box >
	);
};

export default Login;
