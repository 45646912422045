import React, { useState, useRef } from "react";

import globalAxios from "../../globalAxios.js";

import { SERVER_URL } from "../../config.js";
import { useAppSelector, useAppDispatch } from "../../store/hooks.ts";
import {
	updateState,
	setFilesResponse,
} from "../../store/slices/uploadsResponseModalSlice.ts";
import { updateEvents } from "../../store/slices/calendarSlice.ts";
import {
	setSnackbarOpen,
	setSnackbarSeverity,
	setSnackbarText,
} from "../../store/slices/snackbarSlice.ts";
import { openDialog } from "../../store/slices/miniNavSlice.ts";

import {
	// Box,
	CircularProgress,
} from "@mui/material";

import "./DragDrop.css";

interface DragDropProps {
	size?: {
		height?: string;
		width?: string;
	};
}

const DragDrop = (props: DragDropProps) => {
	const fields = useAppSelector((state) => state.fields.fields);

	const [loading, setLoading] = useState<boolean>(false);

	// const [uploadedFiles, setUploadedFiles] = useState<FileList | null>(null);

	// drag state
	const [dragActive, setDragActive] = useState(false);
	// ref
	const inputRef = useRef<HTMLInputElement>(null);
	const dispatch = useAppDispatch();

	// handle drag events
	const handleDrag = function (e) {
		e.preventDefault();
		e.stopPropagation();

		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	};

	// triggers when file is dropped
	const handleDrop = function (e) {
		e.preventDefault();
		e.stopPropagation();

		setDragActive(false);

		if (loading) return;

		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			// setUploadedFiles((e.dataTransfer.files));
			handleConvert(e.dataTransfer.files);
		}
	};

	// triggers when file is selected with click
	const handleChange = function (e) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			// setUploadedFiles((e.target.files));
			handleConvert(e.target.files);
			e.target.value = "";
		}
	};

	// triggers the input when the button is clicked
	const onButtonClick = () => {
		if (inputRef.current) inputRef.current.click();
	};

	const handleConvert = (filesToConvert) => {
		dispatch(setSnackbarOpen(true));
		dispatch(setSnackbarSeverity("info"));
		dispatch(
			setSnackbarText(
				`Converting ${
					filesToConvert ? filesToConvert.length : "0"
				} file${filesToConvert.length > 1 ? "s" : ""}...`
			)
		);

		if (!filesToConvert?.length) {
			alert("No files uploaded.");
			return;
		}

		setLoading(true);
		const data = new FormData();

		// Iterate and append each file to FormData along with its modified_at time.
		for (var x = 0; x < filesToConvert.length; x++) {
			const file = filesToConvert[x];

			// Append the file
			data.append("file", file);
			// Capture the local lastModified time as a string
			const modifiedAtLocal = new Date(file.lastModified);
			const year = modifiedAtLocal.getFullYear();
			const month = String(modifiedAtLocal.getMonth() + 1).padStart(
				2,
				"0"
			);
			const day = String(modifiedAtLocal.getDate()).padStart(2, "0");
			const hours = String(modifiedAtLocal.getHours()).padStart(2, "0");
			const minutes = String(modifiedAtLocal.getMinutes()).padStart(
				2,
				"0"
			);
			const seconds = String(modifiedAtLocal.getSeconds()).padStart(
				2,
				"0"
			);

			const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

			// Append the local time formatted modified_at timestamp
			data.append(`modified_at[${x}]`, formattedDate);
		}

		fields.forEach((field, index) => {
			data.append(`fields[${index}]`, field);
		});

		globalAxios
			.post(SERVER_URL + "/upload", data)
			.then((res) => {
				setLoading(false);

				if (res.status === 200) {
					// Handle res.status 200 object:
					// - If all files succeeded then return success snackbar.
					// - If at least one failed, return a dialog with a list dsplaying the files and their status (succeed or failed).

					dispatch(updateEvents(res.data.newDbSessions));

					// Check if any file upload failed
					const allSucceeded = res.data.filesStatus.every(
						(file) => file.status === "success"
					);

					if (allSucceeded) {
						dispatch(setSnackbarOpen(true));
						dispatch(setSnackbarSeverity("success"));
						dispatch(
							setSnackbarText(
								`Uploaded ${res.data.filesStatus.length} file${
									res.data.filesStatus.length > 1 ? "s" : ""
								} successfully!`
							)
						);
					} else {
						// Show a dialog with the list of files and their statuses
						dispatch(updateState(true));
						dispatch(setFilesResponse(res.data.filesStatus));

						dispatch(openDialog());
					}
				}
				if (res.status === 404) {
					dispatch(setSnackbarOpen(true));
					dispatch(setSnackbarSeverity("error"));
					dispatch(setSnackbarText("Failed to upload."));
				}
			})
			.catch((err) => {
				console.log("error: ", err);
				dispatch(setSnackbarOpen(true));
				dispatch(setSnackbarSeverity("error"));
				dispatch(
					setSnackbarText("An error occurred: " + err.response.data)
				);
				setLoading(false);
			});
	};

	return (
		<>
			<form
				id="form-file-upload"
				style={
					props.size
						? { height: props.size.height, width: props.size.width }
						: {}
				}
				onDragEnter={handleDrag}
				onSubmit={(e) => e.preventDefault()}
			>
				<input
					ref={inputRef}
					type="file"
					id="input-file-upload"
					multiple={true}
					onChange={handleChange}
					disabled={loading ? true : false}
				/>
				<label
					id="label-file-upload"
					htmlFor="input-file-upload"
					className={`${dragActive ? "drag-active" : ""} ${
						loading ? "loading" : ""
					}`}
				>
					<div>
						{Boolean(loading) ? (
							<CircularProgress />
						) : (
							<>
								<p>Drag to Upload or</p>
								<button
									className="upload-button"
									onClick={onButtonClick}
								>
									Click to Upload
								</button>
							</>
						)}
					</div>
				</label>
				{dragActive && (
					<div
						id="drag-file-element"
						onDragEnter={handleDrag}
						onDragLeave={handleDrag}
						onDragOver={handleDrag}
						onDrop={handleDrop}
					></div>
				)}
			</form>
		</>
	);
};

export default DragDrop;

// Reference: https://www.codemzy.com/blog/react-drag-drop-file-upload

// Mosh upload -> backend: https://programmingwithmosh.com/javascript/react-file-upload-proper-server-side-nodejs-easy/
