import React, { useEffect } from "react";
import { SERVER_URL } from "../../config.js";

import {
	Container,
	Paper,
	Avatar,
	Typography,
	Button,
	Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store/hooks.ts";
import { logout } from "../../store/slices/authenticateSlice.ts";
import globalAxios from "../../globalAxios.js";

const Profile = () => {
	const isAuthenticated = useAppSelector((state) => state.authenticate.isAuthenticated)
	const user = useAppSelector(state => state.user);
	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const handleLogout = async () => {
		try {
			await globalAxios.post(`${SERVER_URL}/user/logout`);
			dispatch(logout());
			navigate('/login'); // Redirect to login after logout
		} catch (error) {
			console.error('Logout failed:', error);
			// Optionally handle errors, e.g., show an error message to the user
		};
	};

	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/login'); // Redirect to the path you want the user to go after login
		}
	}, [isAuthenticated, navigate]);

	// Dummy data for the profile
	const userProfile = {
		name: 'John Doe',
		email: 'john.doe@example.com',
		bio: 'Add something interesting about yourself here!.',
		avatarUrl: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==', // Placeholder image URL
	};

	return (
		<Container maxWidth="sm">
			<Paper elevation={0} sx={{ p: 3, mt: 5, textAlign: 'center' }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Avatar
						alt={userProfile.name}
						src={userProfile.avatarUrl}
						sx={{ width: 150, height: 150, mb: 2 }} // Adjust margin-bottom to give space below the avatar
					/>
					<Typography variant="h5" sx={{ mb: 1 }}>
						{user.firstName} {user.lastName}
					</Typography>
					<Typography variant="subtitle1" sx={{ mb: 1 }}>
						{user.email}
					</Typography>
					<Typography variant="body1" sx={{ mb: 2 }}>
						{userProfile.bio}
					</Typography>
					<Button
						variant="contained"
						color="primary"
						disableElevation
						sx={{ color: "white" }}
						onClick={handleLogout}
					>
						Logout
					</Button>
				</Box>
			</Paper>
		</Container>
	);
};

export default Profile;