import React, { useState } from "react";

import globalAxios from "../../../globalAxios.js";

import { SERVER_URL } from "../../../config.js";

import {
	Box,
	Tabs,
	Tab,
	Typography,
	Paper,
	Grid,
	TextField,
	Button,
	IconButton,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import { useAppDispatch, useAppSelector } from "../../../store/hooks.ts";
import {
	aliasMapping,
	setAliasesTab,
	selectAliasesTab,
} from "../../../store/slices/settingsSlice.ts";
import {
	removeAlias,
	selectUserState,
	updateAlias,
} from "../../../store/slices/userSlice.ts";
import {
	setSnackbarOpen,
	setSnackbarSeverity,
	setSnackbarText,
} from "../../../store/slices/snackbarSlice.ts";

const Aliases = () => {
	const dispatch = useAppDispatch();
	const aliasesTab = useAppSelector(selectAliasesTab); // Get the current aliases sub-tab from Redux

	const user = useAppSelector(selectUserState);

	const [unsavedChanges, setUnsavedChanges] = useState(false);

	const handleSubTabChange = (event, newValue) => {
		dispatch(setAliasesTab(newValue)); // Update the Redux state when the sub-tab changes
	};

	const handleAliasChange = (type, index, field, value) => {
		const alias =
			user?.settings?.aliases[
				`${type.replace(" ", "").toLowerCase()}_aliases`
			][index];
		const updatedAlias = { ...alias, [field]: value };

		setUnsavedChanges(true);

		// Dispatch changes to Redux
		dispatch(
			updateAlias({
				aliasType: type,
				alias: updatedAlias,
			})
		);
	};

	const handleSave = () => {
		console.log(user.settings);

		globalAxios
			.post(SERVER_URL + "/user/settings/aliases", user.settings?.aliases)
			.then((res) => {
				dispatch(setSnackbarOpen(true));
				dispatch(setSnackbarSeverity("success"));
				dispatch(setSnackbarText("Saved aliases successfully."));
			})
			.catch((err) => {
				console.error("An error occurred while saving aliases:", err);
				// Handle error (e.g., show an error message)
			});

		setUnsavedChanges(false);
	};

	// Handle the delete alias functionality
	const handleDeleteAlias = (type, aliasId) => {
		// Construct endpoint and alias data
		const url = `${SERVER_URL}/user/settings/aliases/${type
			.replace(" ", "")
			.toLowerCase()}/${aliasId}`;

		globalAxios
			.delete(url)
			.then(() => {
				// After successful deletion, dispatch feedback
				dispatch(setSnackbarOpen(true));
				dispatch(setSnackbarSeverity("success"));
				dispatch(setSnackbarText("Alias deleted successfully."));

				dispatch(
					removeAlias({
						type: type.replace(" ", "").toLowerCase(), // 'hospital', 'surgeon', or 'healthfund'
						id: aliasId, // The ID of the alias to remove
					})
				);
			})
			.catch((err) => {
				console.error(
					"An error occurred while deleting the alias:",
					err
				);
				dispatch(setSnackbarOpen(true));
				dispatch(setSnackbarSeverity("error"));
				dispatch(setSnackbarText("Failed to delete alias."));
			});
	};

	// Render content based on the selected sub-tab
	const renderSubTabContent = () => {
		switch (aliasesTab) {
			case 0:
				return (
					<>
						{user.settings?.aliases?.hospital_aliases.map(
							(alias, index) => (
								<Grid
									container
									spacing={2}
									alignItems="center"
									key={alias.hospital_id}
									sx={{ marginTop: 2 }}
								>
									{/* Alias Name (non-editable) */}
									<Grid item xs={4}>
										<Typography variant="body2">
											{alias.name}
										</Typography>
									</Grid>

									{/* Preferred Name (editable) */}
									<Grid item xs={4}>
										<TextField
											label="Preferred Name"
											variant="outlined"
											size="small"
											fullWidth
											value={alias.preferred_name || ""}
											onChange={(e) =>
												handleAliasChange(
													"Hospital",
													index,
													"preferred_name",
													e.target.value
												)
											}
										/>
									</Grid>

									{/* Preferred Acronym (editable) */}
									<Grid item xs={3}>
										<TextField
											label="Preferred Acronym"
											variant="outlined"
											size="small"
											fullWidth
											value={
												alias.preferred_acronym || ""
											}
											onChange={(e) =>
												handleAliasChange(
													"Hospital",
													index,
													"preferred_acronym",
													e.target.value
												)
											}
										/>
									</Grid>
									<Grid item xs={1}>
										<IconButton
											aria-label="delete"
											onClick={() =>
												handleDeleteAlias(
													"Hospital",
													alias.hospital_id
												)
											}
										>
											<DeleteIcon />
										</IconButton>
									</Grid>
								</Grid>
							)
						)}
					</>
				);
			case 1:
				return (
					<>
						{user.settings?.aliases?.healthfund_aliases?.map(
							(alias, index) => (
								<Grid
									container
									spacing={2}
									alignItems="center"
									key={alias.healthfund_id}
									sx={{ marginTop: 2 }}
								>
									{/* Alias Name (non-editable) */}
									<Grid item xs={4}>
										<Typography variant="body2">
											{alias.name}
										</Typography>
									</Grid>

									{/* Preferred Name (editable) */}
									<Grid item xs={4}>
										<TextField
											label="Preferred Name"
											variant="outlined"
											size="small"
											fullWidth
											value={alias.preferred_name || ""}
											onChange={(e) =>
												handleAliasChange(
													"Health Fund",
													index,
													"preferred_name",
													e.target.value
												)
											}
										/>
									</Grid>

									{/* Preferred Acronym (editable) */}
									<Grid item xs={3}>
										<TextField
											label="Preferred Acronym"
											variant="outlined"
											size="small"
											fullWidth
											value={
												alias.preferred_acronym || ""
											}
											onChange={(e) =>
												handleAliasChange(
													"Health Fund",
													index,
													"preferred_acronym",
													e.target.value
												)
											}
										/>
									</Grid>

									<Grid item xs={1}>
										<IconButton
											aria-label="delete"
											onClick={() =>
												handleDeleteAlias(
													"Health Fund",
													alias.healthfund_id
												)
											}
										>
											<DeleteIcon />
										</IconButton>
									</Grid>
								</Grid>
							)
						)}
					</>
				);
			case 2:
				return (
					<>
						{user.settings?.aliases?.surgeon_aliases?.map(
							(alias, index) => (
								<Grid
									container
									spacing={2}
									alignItems="center"
									key={alias.surgeon_id}
									sx={{ marginTop: 2 }}
								>
									{/* Alias Name (non-editable) */}
									<Grid item xs={4}>
										<Typography variant="body2">
											{alias.name}
										</Typography>
									</Grid>

									{/* Preferred Name (editable) */}
									<Grid item xs={4}>
										<TextField
											label="Preferred Name"
											variant="outlined"
											size="small"
											fullWidth
											value={alias.preferred_name || ""}
											onChange={(e) =>
												handleAliasChange(
													"Surgeon",
													index,
													"preferred_name",
													e.target.value
												)
											}
										/>
									</Grid>

									{/* Preferred Acronym (editable) */}
									<Grid item xs={3}>
										<TextField
											label="Preferred Acronym"
											variant="outlined"
											size="small"
											fullWidth
											value={
												alias.preferred_acronym || ""
											}
											onChange={(e) =>
												handleAliasChange(
													"Surgeon",
													index,
													"preferred_acronym",
													e.target.value
												)
											}
										/>
									</Grid>

									<Grid item xs={1}>
										<IconButton
											aria-label="delete"
											onClick={() =>
												handleDeleteAlias(
													"Surgeon",
													alias.surgeon_id
												)
											}
										>
											<DeleteIcon />
										</IconButton>
									</Grid>
								</Grid>
							)
						)}
					</>
				);
			case 3:
				return <></>;
			default:
				return null;
		}
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
			{/* Sub Tab header */}
			<Box sx={{ borderColor: "divider", marginBottom: "1rem" }}>
				<Tabs
					value={aliasesTab}
					onChange={handleSubTabChange}
					aria-label="Aliases Sub Tabs"
					sx={{ minHeight: "2.5rem", maxHeight: "2.5rem" }}
				>
					{aliasMapping.map((alias, index) => (
						<Tab
							key={index}
							sx={{ minHeight: "2.5rem", maxHeight: "2.5rem" }}
							label={alias} // Capitalize first letter of each alias
						/>
					))}
				</Tabs>
			</Box>

			{/* Sub Tab content */}
			<Box sx={{ flexGrow: 1 }}>
				<Paper elevation={2} sx={{ padding: 2 }}>
					{renderSubTabContent()}{" "}
					{/* Render the content based on the selected sub-tab */}
				</Paper>
			</Box>
			{unsavedChanges && (
				<Box sx={{ padding: 2, textAlign: "right" }}>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSave}
					>
						Save Changes
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default Aliases;
