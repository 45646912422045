// Controlled component for calendar.
import React from "react";

import {
	DialogTitle,
	Button,
	DialogContent,
	Box,
	DialogActions,
	CircularProgress,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	Dialog,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { calculateFeeEstimate } from "../helper/feeCalculation.ts";

const CalendarDialog = ({
	open,
	selectedEvent,
	schedules,
	deleting,
	handleDelete,
	handleClose,
}) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
			PaperProps={{
				sx: {
					maxWidth: "80vw", // Set max-width to 80vw
				},
			}}
		>
			<DialogTitle id="form-dialog-title">
				Session Details
				<Button
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					Close
				</Button>
			</DialogTitle>
			<DialogContent>
				<Box>
					<p>
						{selectedEvent?.start
							? new Date(selectedEvent.start).toLocaleDateString()
							: ""}
					</p>
					<p>
						<b>{selectedEvent?.hospital}</b>
					</p>
					<p>
						<b>{selectedEvent?.surgeon}</b>
					</p>
					<p>
						Assistant Estimate:&nbsp;
						<b>
							{calculateFeeEstimate(
								selectedEvent.events,
								schedules
							)
								? `$${calculateFeeEstimate(
										selectedEvent.events,
										schedules
								  )?.toFixed(2)}`
								: "$0.00"}
						</b>
					</p>
				</Box>
			</DialogContent>
			<DialogActions>
				{/* <Button onClick={handleEdit} color="primary">
                    Edit
                </Button> */}
				{deleting ? (
					<Button disabled>
						<svg width={0} height={0}>
							<defs>
								<linearGradient
									id="my_gradient"
									x1="0%"
									y1="0%"
									x2="0%"
									y2="100%"
								>
									<stop offset="0%" stopColor="#e01cd5" />
									<stop offset="100%" stopColor="#1CB5E0" />
								</linearGradient>
							</defs>
						</svg>
						<CircularProgress
							size={18}
							sx={{
								"svg circle": {
									stroke: "url(#my_gradient)",
								},
							}}
						/>
					</Button>
				) : (
					<Button
						variant="contained"
						onClick={handleDelete}
						color="error"
						size="small"
						disableElevation
					>
						Delete Session
					</Button>
				)}
			</DialogActions>
			<DialogContent>
				<Box>
					{selectedEvent?.events?.length > 0 ? (
						selectedEvent.events.map((event, index) => {
							// Calculate individual patient fee estimate

							return (
								<Accordion key={index}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
									>
										<Typography variant="body1">
											<b>Patient:</b>{" "}
											{event.patient.first_name}{" "}
											{event.patient.last_name} |{" "}
											<b>Procedure:</b> {event.procedure}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography variant="body2">
											DOB: {event.patient.date_of_birth}
										</Typography>
										<Typography variant="body2">
											Medicare: {event.patient.medicare}
										</Typography>
										<Typography variant="body2">
											Health Fund:{" "}
											{Array.isArray(
												event.patient.policies
											) &&
											event.patient.policies.length > 0
												? event.patient.policies[0]
														.healthFund?.name
													? event.patient.policies[0]
															.healthFund.name // Use health fund name if it exists
													: event.patient.policies[0]
															.healthFund
															.aliases[1] // Fallback to aliases[1] if no name exists
												: "N/A"}
										</Typography>
										<Typography variant="body2">
											Health Fund #:{" "}
											{Array.isArray(
												event.patient.policies
											) &&
											event.patient.policies.length > 0
												? event.patient.policies[0]
														.health_fund_no
												: "N/A"}
										</Typography>
										<Typography variant="body2">
											Items:{" "}
											{(() => {
												let itemsArray = [];
												try {
													itemsArray = JSON.parse(
														event.actual_items
													);
												} catch (error) {
													console.error(
														"Error parsing actual_items:",
														error
													);
												}
												return Array.isArray(
													itemsArray
												) && itemsArray.length > 0
													? itemsArray.join(", ")
													: "";
											})()}
										</Typography>
										<Typography variant="body2">
											Anaesthetist:{" "}
											{event.anaesthetist?.name || "N/A"}
										</Typography>
										<br />
										{/* Individual Patient Fee Estimate */}
										<Typography variant="body2">
											Assistant Estimate:&nbsp;
											<b>
												$
												{`${event.feeEstimate?.toFixed(
													2
												)}`}
											</b>
										</Typography>
									</AccordionDetails>
								</Accordion>
							);
						})
					) : (
						<Typography variant="body2">
							No additional events available.
						</Typography>
					)}
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default CalendarDialog;
