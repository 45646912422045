import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface AuthenticateState {
	isLoading: boolean
	isAuthenticated: boolean | null
	accessToken: string | null
}

// Define the initial state using that type
const initialState: AuthenticateState = {
	isLoading: false,
	isAuthenticated: null,
	accessToken: null,
}

export const authenticateSlice = createSlice({
	name: 'authenticate',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setLoading(state, action) {
			state.isLoading = action.payload;
		},
		setAuth: (state, action: PayloadAction<boolean>) => {
			state.isAuthenticated = action.payload
		},
		setToken: (state, action: PayloadAction<string>) => {
			state.accessToken = action.payload
		},
		logout: (state) => {
			state.isLoading = false;
			state.isAuthenticated = false;
			state.accessToken = null;
		},
	}
})

export const { setLoading, setAuth, setToken, logout } = authenticateSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectAuthenticateState = (state: RootState) => state.authenticate;

export default authenticateSlice.reducer