// If any Pt. has no medicare or healthfund the entire session gets flagged.
export const determineSessionFundingStatus = (session) => {

	// Iterate through each session
	for (const event of session) {
		const { medicare, policies } = event.patient;

		// Check if medicare exists and if there is at least one valid health_fund_id in the policies array
		const hasMedicare = Boolean(medicare);
		const hasValidPolicy = policies.some(policy => typeof policy.health_fund_id === 'number');

		// If either medicare is missing or no valid policy exists, return false
		if (!hasMedicare || !hasValidPolicy) {
			return false;
		}
	}

	// If all sessions have medicare and a valid health fund, return true
	return true;
};