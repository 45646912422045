import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { SERVER_URL } from './config';
import { store } from './store/store.ts';
import { logout, setToken } from './store/slices/authenticateSlice.ts';


const globalAxios = axios.create({
	baseURL: SERVER_URL,
	withCredentials: true,
});

globalAxios.interceptors.request.use(async config => {

	const token = store.getState().authenticate.accessToken;

	if (token && jwtDecode(token).exp < Date.now() / 1000) {
		// Token is expired or about to expire, or doesn't exist (ie. not in redux state)
		return refreshToken().then(newToken => {
			config.headers['Authorization'] = `Bearer ${newToken}`;
			return config;
		});
	};

	config.headers.Authorization = `Bearer ${token}`;

	return config;
});


globalAxios.interceptors.response.use(
	response => response, // Simply return the response for successful requests
	error => {
		// Check if the error response is due to unauthorized access
		if (error.response && (error.response.status === 401 || error.response.status === 403)) {
			// Dispatch an action to update the state to reflect that the user should be logged out
			store.dispatch(logout());
		};
		return Promise.reject(error);
	}
);


// Another axios instance used for refresh token route to prevent circular calls.
const plainAxios = axios.create({
	baseURL: SERVER_URL,
	withCredentials: true,
});


plainAxios.interceptors.response.use(
	response => response, // Simply return the response for successful requests
	error => {
		// Check if the error response is due to unauthorized access
		if (error.response && (error.response.status === 401 || error.response.status === 403)) {
			// Dispatch an action to update the state to reflect that the user should be logged out
			store.dispatch(logout());
		};
		return Promise.reject(error);
	}
);

export const refreshToken = async () => {
	// Implement call to refresh-token endpoint
	const response = await plainAxios.post('/refresh-token');
	const { accessToken } = response.data;
	store.dispatch(setToken(accessToken)); // Update the new token in the store
	return accessToken;
};

export default globalAxios;
