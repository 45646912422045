
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '../../store/hooks.ts';
// import { setAuth } from '../../store/slices/authenticateSlice.ts';

const ProtectedRoute = () => {
	const isAuthenticated = useAppSelector((state) => state.authenticate.isAuthenticated);

	if (isAuthenticated === null) {
		return null;
	};

	if (!isAuthenticated) {
		// User not authenticated, redirect to login page
		return <Navigate to="/login" replace />;
	};

	return <Outlet />; // Render children routes if authenticated
};

export default ProtectedRoute;


// Specifically for admin users
const AdminRoute = () => {
	const isAdmin = useAppSelector(state => state.user.admin);
	const isAuthenticated = useAppSelector(state => state.authenticate.isAuthenticated);

	if (!isAuthenticated) {
		return <Navigate to="/login" replace />;
	}

	if (!isAdmin) {
		// User not authenticated, redirect to login page
		return <Navigate to="/" replace />;
	};

	return <Outlet />;
};

export { AdminRoute };