import { jwtDecode } from 'jwt-decode';
import { store } from './store/store.ts';
import { refreshToken } from './globalAxios.js';


const jwtCheck = async () => {
	const token = store.getState().authenticate.accessToken;
	if (!token) {
		return false;
	};

	try {
		const decodedToken = jwtDecode(token);
		const currentTime = Date.now() / 1000; // in seconds

		if (decodedToken.exp < currentTime) {
			// Handle the expired token case, e.g., redirect to login or refresh the token
			const accessToken = await refreshToken();
			if (accessToken) {
				return true;
			} else {
				return false;
			};
		};

		return true;
	} catch (error) {
		return false;
	};
};

export default jwtCheck;
