import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface FileStatus {
	file: string;
	status: string;
	message?: string;
}

// Define a type for the slice state
export interface UploadsResponseModalState {
	active: boolean;
	filesStatus: FileStatus[];  // Array of file status objects
}

// Define the initial state using that type
const initialState: UploadsResponseModalState = {
	active: false,
	filesStatus: [],
}

export const uploadsResponseModalSlice = createSlice({
	name: 'uploadsResponseModal',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		updateState: (state, action: PayloadAction<boolean>) => {
			state.active = action.payload
		},
		setFilesResponse: (state, action: PayloadAction<FileStatus[]>) => {
			state.filesStatus = action.payload;
		},
	}
})

export const { updateState, setFilesResponse } = uploadsResponseModalSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectUploadsResponseModalState = (state: RootState) => state.uploadsResponseModal.active;

export default uploadsResponseModalSlice.reducer