import React from "react";
import { useAppSelector, useAppDispatch } from '../../store/hooks.ts';
import { setSnackbarOpen } from '../../store/slices/snackbarSlice.ts';

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

export const GlobalSnackbar = () => {

	const open = useAppSelector(state => state.snackbar.open);
	const severity = useAppSelector(state => state.snackbar.severity);
	const text = useAppSelector(state => state.snackbar.text);

	const dispatch = useAppDispatch();

	return (
		<Snackbar
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			open={open}
			autoHideDuration={4000}
			onClose={() => dispatch(setSnackbarOpen(false))}
		>
			<Alert
				severity={severity}
				variant="filled"
				sx={{ width: '100%', }}
			>
				{text}
			</Alert>
		</Snackbar>
	);
};

export default GlobalSnackbar;