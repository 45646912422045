import React from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks.ts';
import { setActiveTab, selectActiveTab } from '../../store/slices/settingsSlice.ts';

import {
	Box,
	Tabs,
	Tab,
	Paper
} from '@mui/material';

import Fields from "./content/Fields.tsx"
import Aliases from "./content/Aliases.tsx"

import './Settings.css';

const Settings = () => {
	const dispatch = useAppDispatch();
	const activeTab = useAppSelector(selectActiveTab);

	const handleTabChange = (_event, newValue) => {
		dispatch(setActiveTab(newValue)); // Update active tab in Redux state
	};


	const renderTabContent = () => {
		switch (activeTab) {
			case 0:
				return <Fields />;  // Choose Fields content
			case 1:
				return <Aliases />;  // Aliases content (You can add this as a separate component)
			default:
				return null;
		}
	};
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			{/* Tab header */}
			<Box sx={{ borderColor: 'divider' }}>
				<Tabs
					value={activeTab}
					onChange={handleTabChange}
					aria-label="Settings Tabs"
					sx={{ minHeight: "2.5rem", maxHeight: "2.5rem" }}
				>
					<Tab sx={{ minHeight: "2.5rem", maxHeight: "2.5rem" }} label="Choose Fields" />
					<Tab sx={{ minHeight: "2.5rem", maxHeight: "2.5rem" }} label="Aliases" />
					{/* You can add more tabs here as needed */}
				</Tabs>
			</Box>

			{/* Tab content */}
			<Box sx={{ flexGrow: 1 }}>
				<Paper elevation={2} sx={{ padding: 2 }}>
					{renderTabContent()}  {/* Render the content based on the selected tab */}
				</Paper>
			</Box>
		</Box >
	);
};

export default Settings;
